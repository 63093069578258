import React, {forwardRef} from 'react';
import {DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarExport, GridToolbarQuickFilter} from '@mui/x-data-grid-pro';
import {Box, Stack, useTheme, useMediaQuery} from '@mui/material';
import {get} from 'lodash';
import {unflatten} from 'flat';

import StatefulDataGrid from './StatefulDataGrid';

function Toolbar() {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <GridToolbarContainer>
            {!isSmall && (
                <Stack direction="row" spacing={1} sx={{flex: 1}}>
                    <GridToolbarColumnsButton />
                    <GridToolbarExport />
                </Stack>
            )}

            <GridToolbarQuickFilter variant="outlined" size="small" sx={{...isSmall && {flex: 1}}} />
        </GridToolbarContainer>
    );
}

export default forwardRef((props, ref) => {
    const {stateId, columns = [], rows = [], showTotals, loading, disableToolbar, ...rest} = props;
    const Component = stateId ? StatefulDataGrid : DataGridPro;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    let allRows = rows;
    if (showTotals) {
        allRows = [...rows];
        if (showTotals) {
            const totalRow = columns.reduce((result, column) => {
                const {field, total: config} = column;

                if (config) {
                    if (config === 'sum') {
                        result[field] = allRows.reduce((total, row) => total + get(row, field, 0), 0);
                    } else if (config === 'average') {
                        result[field] = allRows.reduce((total, row) => total + get(row, field, 0), 0) / allRows.length;
                    } else {
                        result[field] = '';
                    }
                }

                return result;
            }, {});

            allRows.push({
                id: 'total',
                ...unflatten(totalRow)
            });
        }
    }

    return (
        <Box sx={{width: '100%'}}>
            <Box style={{display: 'flex', flexDirection: 'column', minHeight: !allRows || allRows.length === 0 || loading ? 250 : 'auto'}}>
                <Component
                    ref={ref}
                    stateId={stateId}
                    disableColumnFilter
                    disableDensitySelector
                    slots={{toolbar: !disableToolbar && Toolbar}}
                    getRowClassName={row => (row.id === 'total' ? 'total-row' : '')}
                    rows={allRows}
                    loading={loading}
                    columns={columns.map(column => {
                        return {
                            disableColumnMenu: isSmall,
                            ...column
                        };
                    })}
                    {...rest}
                />
            </Box>
        </Box>
    );
});