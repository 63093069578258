import React, {useState, useCallback, useEffect, useContext} from 'react';
import {Box, Link, Typography, Card, CardContent, Skeleton} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {ref, getDownloadURL} from 'firebase/storage';
import {get} from 'lodash';

import {storage} from '-/firebase.js';
import {UserContext} from '-/contexts/User';

import {uploadFile} from '-/data/utils';

const PDF = ({url}) => {
    if (!url) {
        return null;
    }

    return (
        <Worker workerUrl={'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'}>
            <Viewer fileUrl={url} />
        </Worker>
    );
};

export default function User(props) {
    const {user = {}} = props;

    const {uid, files = []} = user || {};
    const transcriptFile = files.find(file => file.grouping === 'jibcTranscript');
    const [transcriptUrl, setTranscriptUrl] = useState(null);

    const [loading, setLoading] = useState(false);
    const [loadingFile, setLoadingFile] = useState(transcriptFile);
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';

    const fetchTranscript = useCallback(async () => {
        const {filePath} = transcriptFile || {};
        if (!filePath) {
            setTranscriptUrl(null);
            return;
        }

        try {
            const url = await getDownloadURL(ref(storage, transcriptFile.filePath));
            setTranscriptUrl(url);
        } catch(e) {
            console.warn(e);
        }
        
        setLoadingFile(false);
    }, [transcriptFile]);

    useEffect(() => {
        fetchTranscript();
    }, [transcriptFile]);

    const handleFileUpload = useCallback(async file => {
        if (!file || !uid) {
            return;
        }

        setLoading(true);
        
        try {
            await uploadFile(`users/${uid}`, file, 'jibcTranscript', true);

            setLoading(false);
            
            enqueueSnackbar('Transcript uploaded successfully!', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message || 'Error uploading transcript', {variant: 'error'});
        }

        setLoading(false);
    }, [uid, enqueueSnackbar]);

    return (
        <Box>
            <Card variant="outlined" sx={{background: '#f5f5f5'}}>
                <CardContent sx={{pb: '16px !important'}}>
                    <Typography component="ol" variant="subtitle2" sx={{flex: 1, paddingInlineStart: 2}}>
                        <li>Go to  <Link href="https://jibc-selfservice.colleagueservices.ca/Student/Student/Transcripts" target="_blank">https://jibc-selfservice.colleagueservices.ca/Student/Student/Transcripts</Link></li>
                        <li>Log in with JIBC your student number & password</li>
                        <li>
                            Click on the link that looks like this:
                            <br />
                            <img src="/download-transcript.png" alt="Download JIBC Transcript" style={{width: 301, borderRadius: 10}} />
                        </li>
                        <li>Your transcript will save to your download folder</li>
                        <li>Upload the transcript PDF file using the button below</li>
                    </Typography>
                </CardContent>
            </Card>

            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 2}}>
                {transcriptFile && (
                    <Typography variant="caption" sx={{mr: 2}}>
                        {`Uploaded ${moment(transcriptFile.createdAt).format(dateFormat)}`}
                    </Typography>
                )}
                <LoadingButton loading={loading} variant="contained" component="label">
                    Upload JIBC Transcript
                    <input
                        type="file"
                        hidden
                        accept="application/pdf"
                        onChange={e => {
                            const file = e.target.files[0];
                            handleFileUpload(file);
                            e.target.value = '';
                        }}
                    />
                </LoadingButton>
            </Box>

            {!!transcriptFile && (
                <Card variant="outlined" sx={{mt: 2}}>
                    {loadingFile ? (
                        <Skeleton variant="rectangular" sx={{height: 500}} />
                    ) : (
                        <Box sx={{py: 2}}>
                            <PDF url={transcriptUrl} />
                        </Box>
                    )}
                </Card>
            )}
        </Box>
    );
}