import {useEffect, useContext} from 'react';

import {SettingsContext} from '-/contexts/Settings';

export default function(title) {
    const {siteName = 'Ember Tracking'} = useContext(SettingsContext);

    useEffect(() => {
        document.title = title ? `${title} | ${siteName}` : siteName;
    }, [title]);

    return null;
}