import React, {useContext} from 'react';
import {Box, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, TextField} from '@mui/material';
import {People as PeopleIcon} from '@mui/icons-material';
import {isNil, isNaN, isString, sortBy} from 'lodash';

import {UserContext} from '-/contexts/User';

const HoursTable = props => {
    const {users = []} = useContext(UserContext);
    const {members: membersRaw = [], defaultSalary = 0, salaryOverrides = {}, handleHourChange} = props;

    const members = membersRaw.map(member => {
        if (isString(member)) {
            return users.find(user => user.uid === member);
        }

        return member;
    });
    
    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <PeopleIcon sx={{fontSize: 20}} />
                            <Box sx={{ml: 1}}>Members</Box>
                        </TableCell>
                        <TableCell>Hours</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(members || []).filter(Boolean).length ? sortBy(members, ['lastName', 'firstName']).map((row, index) => {
                        const {fullName} = row || {};
                        const value = row && (salaryOverrides || {})[row.id];

                        return (
                            <TableRow
                                key={`detail-member-${index}`}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell scope="row">
                                    {fullName}
                                </TableCell>
                                <TableCell scope="row">
                                    <TextField
                                        size="small"
                                        sx={{width: 50}}
                                        hiddenLabel
                                        variant="standard"
                                        type="number"
                                        inputProps={{min: 0, step: 0.5}}
                                        placeholder={`${defaultSalary}`}
                                        value={isNil(value) || isNaN(value) ? '' : value}
                                        onChange={e => handleHourChange(e, row)}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    }) : (
                        <TableRow>
                            <TableCell colSpan={2} sx={{textAlign: 'center'}}>
                                No members
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default HoursTable;