import React, {useContext} from 'react';
import {useController} from 'react-hook-form';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {get, capitalize} from 'lodash';
import moment from 'moment';

import {UserContext} from '-/contexts/User';

export default function ControlledTextField(props) {
    const {name, rule: rawRules, required, label, views, format, readOnly, helperText, fullWidth, disabled, sx, ...rest} = props;
    const rules = {...rawRules};
    const fieldLabel = label !== false ? label || capitalize(name) : null;
    const {currentUser} = useContext(UserContext);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';

    if (required && !rules.required) {
        rules.required = fieldLabel ? `${fieldLabel} is required` : 'This field is required';
    }

    const {field, fieldState: {error}} = useController({name, rules});
    const {onChange, value, ...restField} = field;

    return (
        <DatePicker
            label={label}
            format={format || dateFormat}
            readOnly={readOnly}
            disabled={disabled}
            {...{
                ...(sx && {sx}),
                ...(format && {format}),
                ...(views && {views})
            }}
            {...restField}
            value={value || null}
            onChange={date => onChange(date ? moment(date && date.toDate()) : null)}
            slotProps={{
                textField: {
                    ...rest,
                    required,
                    error: !!error,
                    helperText: error && helperText !== false ? error.message : helperText,
                    fullWidth
                }
            }}
        />
    );
};
