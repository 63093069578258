import React, {useState, useCallback, useContext} from 'react';
import {Card, CardActionArea, CardContent, CircularProgress, Grid2 as Grid, Button, Typography, Box, Container} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {signInWithEmailAndPassword, sendPasswordResetEmail, signInWithCustomToken} from 'firebase/auth';
import {useForm, FormProvider} from 'react-hook-form';
import {query, collection, where} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import {httpsCallable} from 'firebase/functions';

import {db, auth, functions} from '-/firebase';

import {getCollection} from '-/data/utils';

import {SettingsContext} from '-/contexts/Settings';
import {StationsContext} from '-/contexts/Stations';

import TextField from '-/form/TextField.js';
import PasswordField from '-/form/PasswordField.js';

import FirebaseImage from '-/components/FirebaseImage';

import useDocumentTitle from '-/hooks/useDocumentTitle';

import firebaseConfig from '-/firebase-config.json';

export default function Login() {
    const [loading, setLoading] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const [loggingInStation, setLoggingInStation] = useState(false);
    const [registering, setRegistering] = useState(false);
    const [error, setError] = useState(null);
    const {enqueueSnackbar} = useSnackbar();
    const loginAsStation = httpsCallable(functions, 'loginAsStation');
    const registerUser = httpsCallable(functions, 'registerUser');
    const {image, allowStationLogin, allowRegistrationWithoutUser, limitToDomain, domain} = useContext(SettingsContext);
    const {stations} = useContext(StationsContext);

    useDocumentTitle('Login');

    const isDemo = firebaseConfig.projectId === 'embertracking-demo';

    const methods = useForm({
        defaultValues: {
            email: isDemo ? 'demo@embertracking.com' : '',
            password: isDemo ? 'password' : '',
            confirmPassword: ''
        },
        mode: 'onChange'
    });
    const {handleSubmit, formState, watch} = methods;
    const {isValid} = formState;

    const email = watch('email');
    const password = watch('password');

    let pattern, validate;
    if (limitToDomain) {
        pattern = {
            value: new RegExp(`^[A-Z0-9._%+-]+@${domain}$`, 'i'),
            message: `You must use an @${domain} email address`
        };
    } else {
        pattern = {
            value: /\S+@\S+\.\S+/,
            message: 'Invalid email address'
        };
    }

    const onResetPassword = useCallback(async () => {
        setLoading(true);

        try {
            await sendPasswordResetEmail(auth, email);

            enqueueSnackbar(`Reset password email sent to ${email}. Please check your email.`);
        } catch (e) {
            setLoading(false);

            setError(e.message);
        }

        setLoading(false);
    }, [auth, email, enqueueSnackbar]);

    const onCancel = useCallback(() => {
        setRegistering(false);
        setLoggingIn(false);
    }, []);

    const handleStationLogin = async station => {
        setLoggingInStation(station);
        setLoading(true);
        setError(null);

        if (loggingInStation) {
            return;
        }

        try {
            const {uid} = station;
            const {data} = await loginAsStation({station: uid});
            const {success, token} = data || {};

            if (!success) {
                throw new Error('Error logging in as station');
            }

            await signInWithCustomToken(auth, token);
        } catch(e) {
            setLoading(false);
            setLoggingInStation(false);

            enqueueSnackbar('Error logging in as station. Please try again or contact the administrator', {variant: 'error'});
        }
    };

    const onSubmit = useCallback(async data => {
        setLoading(true);
        setError(null);

        const {password} = data;
        let {email = ''} = data;
        email = email.toLowerCase().trim();

        try {
            const ref = collection(db, 'users');
            const q = query(ref, where('email', '==', email));
            const [existingUser] = await getCollection(db, q);

            if (registering) {
                await registerUser({email, password});

                setTimeout(async() => {
                    await signInWithEmailAndPassword(auth, email, password);
                }, 1000);
            } else if (loggingIn) {
                await signInWithEmailAndPassword(auth, email, password);
            } else {
                if (!existingUser && !allowRegistrationWithoutUser) {
                    throw new Error('No user found with that email address. Please contact the system administrator.');
                }

                const {registered = false} = existingUser || {};
                if (registered) {
                    setLoggingIn(true);
                } else {
                    setRegistering(true);
                }

                setLoading(false);
            }
        } catch (e) {
            setLoading(false);

            const {code} = e || {};
            if (code === 'auth/wrong-password') {
                setError('Incorrect password');
            } else if (code === 'auth/user-not-found') {
                setError('No user found with that email address');
            } else {
                setError(e.message);
            }
        }
    }, [auth, registering, loggingIn, db]);

    return (
        <FormProvider {...methods}>
            <Container component="main" maxWidth="s" sx={{maxWidth: 400}}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch'
                    }}
                >
                    <Box sx={{mt: 1, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        {image ? (
                            <FirebaseImage image={image} sx={{width: '60%'}} />
                        ) : (
                            <img src="/logo.png" alt="Ember Tracking" style={{width: '60%'}} />
                        )}
                    </Box>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                        <TextField
                            sx={{mt: 2}}
                            fullWidth
                            label="Email"
                            name="email"
                            autoComplete="email"
                            disabled={loading || loggingIn || registering}
                            rules={{required: true, pattern, validate}}
                            {...{
                                ...(error && {error: true, helperText: error})
                            }}
                        />

                        <PasswordField
                            sx={{mt: 2, display: (loggingIn || registering) ? 'block' : 'none'}}
                            fullWidth
                            label="Password"
                            name="password"
                            autoComplete="current-password"
                            disabled={loading}
                            rules={{required: loggingIn}}
                        />

                        {registering && (
                            <>
                                <PasswordField
                                    sx={{mt: 2}}
                                    rules={{
                                        required: true,
                                        validate: value => value !== password ? 'Passwords do not match' : true
                                    }}
                                    fullWidth
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    autoComplete="new-password"
                                    disabled={loading}
                                />
                            </>
                        )}
                        <LoadingButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 2}}
                            onClick={handleSubmit(onSubmit)}
                            disabled={loading || !isValid}
                            loading={loading && !loggingInStation}
                        >
                            {registering ? 'Register' : loggingIn ? 'Sign In' : 'Next'}
                        </LoadingButton>
                        {loggingIn && (
                            <Button
                                fullWidth
                                variant="outlined"
                                sx={{mt: 1}}
                                onClick={onResetPassword}
                                disabled={loading}
                            >
                                Reset Password
                            </Button>
                        )}
                        {(loggingIn || registering) && (
                            <>
                                <Button
                                    fullWidth
                                    sx={{mt: 1}}
                                    onClick={onCancel}
                                    disabled={loading}
                                >
                                    Cancel
                                </Button>
                            </>
                        )}

                        {allowStationLogin && !loggingIn && !registering && (
                            <>
                                <Typography component="div" variant="caption" sx={{mt: 4, mb: 2, textAlign: 'center'}}>OR LOGIN AS</Typography>

                                <Grid spacing={1} container>
                                    {stations.map(station => {
                                        const {uid, name} = station;
                                        const isLoggingIn = loggingInStation && loggingInStation.uid === uid;

                                        return (
                                            <Grid key={`login-station-${uid}`} size={6}>
                                                <Card>
                                                    <CardActionArea disabled={!!loggingInStation} onClick={() => handleStationLogin(station)} sx={{aspectRatio: '1/1'}}>
                                                        <CardContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                            <Typography variant="caption">STATION</Typography>
                                                            <Typography variant="h6" sx={{fontSize: '4rem', lineHeight: 1}}>
                                                                {isLoggingIn ? <CircularProgress /> : name}
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </>
                        )}
                    </Box>
                </Box>
            </Container>
        </FormProvider>
    );
};