import React, {useState, useCallback, useContext, useEffect} from 'react';
import {Paper, Skeleton, IconButton, InputAdornment, Box, Tabs, Tab, Typography, Divider, Grid2 as Grid, Button} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useNavigate, useParams} from 'react-router-dom';
import {useForm, FormProvider} from 'react-hook-form';
import {signInWithCustomToken} from 'firebase/auth';
import {doc, onSnapshot, updateDoc, collection, query, where, getDocs} from 'firebase/firestore';
import {httpsCallable} from 'firebase/functions';
import {useSnackbar} from 'notistack';
import {omit} from 'lodash';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import {Ranks} from '@embertracking/common';

import {UserContext} from '-/contexts/User';
import {SettingsContext} from '-/contexts/Settings';
import {auth, db, functions} from '-/firebase';
import {hasFeature} from '-/features';

import {processUserName, processRawDoc, hasPermission, ensureJSDates, ensureMomentDates, uploadImage} from '-/data/utils';

import useDocumentTitle from '-/hooks/useDocumentTitle';

import TextField from '-/form/TextField.js';
import PasswordField from '-/form/PasswordField.js';
import SelectField from '-/form/SelectField.js';
import CheckboxField from '-/form/CheckboxField';
import DatePickerField from '-/form/DatePickerField';
import PhoneField from '-/form/PhoneField';
import ImageUploadField from '-/form/ImageUploadField';
import StationsField from '-/form/StationsField';

import Qualifications from './Qualifications';
import Statistics from './Statistics';
import JIBCTranscript from './JIBCTranscript';
import JIBCConsent from './JIBCConsent';
import Permissions from './Permissions';

export default function User(props) {
    const {isProfile, activeTab = 'edit'} = props;

    const {id: uid} = useParams();
    const isNew = !uid && !isProfile;
    const [loading, setLoading] = useState(!!uid);
    const [deleting, setDeleting] = useState(false);
    const [editingEmail, setEditingEmail] = useState(isNew);
    const [sendingEmailVerification, setSendingEmailVerification] = useState(isNew);
    const [impersonating, setImpersonating] = useState(false);
    const navigate = useNavigate();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    
    const {limitToDomain, domain, usesJIBC} = useContext(SettingsContext);
    const {currentUser} = useContext(UserContext);
    const [user, setUser] = useState(isProfile ? currentUser : undefined);
    const deleteUser = httpsCallable(functions, 'deleteUser');
    const registerUser = httpsCallable(functions, 'registerUser');
    const updateUserEmail = httpsCallable(functions, 'updateUserEmail');
    const updateUserPassword = httpsCallable(functions, 'updateUserPassword');
    const updateUserMetadata = httpsCallable(functions, 'updateUserMetadata');
    const sendEmailVerification = httpsCallable(functions, 'sendEmailVerification');
    const impersonateUser = httpsCallable(functions, 'impersonateUser');

    const currentUserIsAdmin = hasPermission(currentUser);
    const isCurrentUser = (uid === currentUser.uid) || isProfile;

    let pattern;
    if (limitToDomain) {
        pattern = {
            value: new RegExp(`^[A-Z0-9._%+-]+@${domain}$`, 'i'),
            message: `You must use an @${domain} email address`
        };
    } else {
        pattern = {
            value: /\S+@\S+\.\S+/,
            message: 'Invalid email address'
        };
    }

    const methods = useForm({
        defaultValues: {
            email: '',
            role: '',
            dob: null,
            station: '',
            firstName: '',
            lastName: '',
            imageUrl: '',
            imageThumbnailUrl: '',
            imageFile: '',
            employeeId: '',
            jibcNumber: '',
            phone: '',
            ...user,
            settings: {
                dateFormat: ''
            }
        },
        mode: 'onChange'
    });
    const {handleSubmit, reset, watch, formState} = methods;
    const {dirtyFields} = formState;

    const imageFile = watch('imageFile');
    const password = watch('password');
    const registered = watch('registered');
    const email = watch('email');
    const fullName = watch('fullName');
    const emailVerified = watch('emailVerified');
    const lastSignIn = watch('lastSignIn');

    const dateFormat = watch('settings.dateFormat');
    const dateFormatLong = watch('settings.dateFormatLong');
    const dateFormatShort = watch('settings.dateFormatShort');

    const fetch = useCallback(() => {
        if (uid) {
            const ref = doc(db, 'users', uid);
            const unsubscribe = onSnapshot(ref, snapshot => {
                const row = processRawDoc(snapshot);
                reset(row);
                setUser(row);
                setLoading(false);
            });

            return unsubscribe;
        }
    }, [uid, db]);

    const fetchMetadata = useCallback(async () => {
        if (uid && (hasPermission(currentUser, 'users.write') || isProfile)) {
            try {
                await updateUserMetadata({uid});
            } catch(e) {
                console.warn(e);
            }
        }
    }, [uid, db]);
    
    useEffect(() => {
        fetch();
        fetchMetadata();
    }, [db, uid]);

    useEffect(() => {
        if (isProfile) {
            setUser(currentUser);
            reset(currentUser);
        }
    }, [user, currentUser, isProfile, reset, navigate]);

    useDocumentTitle(isProfile ? 'Profile' : (isNew ? 'New User' : (fullName || email)));

    const handleImpersonate = useCallback(async() => {
        try {
            setImpersonating(true);

            const {data} = await impersonateUser({uid});
            const {token} = data;

            const currentUserToken = await auth.currentUser.getIdToken();
            localStorage.setItem('impersonating', currentUserToken);

            await signInWithCustomToken(auth, token);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setImpersonating(false);
    }, [uid, auth, impersonateUser, enqueueSnackbar]);

    const handleEmailVerification = useCallback(async() => {
        setSendingEmailVerification(true);

        try {
            await sendEmailVerification({uid});
            enqueueSnackbar('Verification email sent', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setSendingEmailVerification(false);
    }, [uid, sendEmailVerification, enqueueSnackbar]);

    const handleDelete = useCallback(() => {
        const onDelete = async() => {
            try {
                setDeleting(true);

                await deleteUser({uid});

                navigate(-1);

                enqueueSnackbar('User deleted', {variant: 'success'});
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
    
            setDeleting(false);
        };

        enqueueSnackbar('Are you sure you want to delete this user?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Dismiss
                        </Button>
                    </>
                );
            }
        });
    }, [enqueueSnackbar, navigate, uid, closeSnackbar, deleteUser]);

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const newData = ensureJSDates(processUserName(omit(data, [
            'imageFile',
            'password',
            'confirmPassword'
        ])));

        const {email} = data;

        try {
            if (isNew) {
                const ref = collection(db, 'users');
                const q = query(ref, where('email', '==', email));
                const raw = await getDocs(q);
                const {size} = raw;
                
                if (size > 0) {
                    enqueueSnackbar(`User with email '${email}' already exists`, {variant: 'error'});
                } else {
                    const {data} = await registerUser({
                        email,
                        password
                    });
                    const {uid} = data || {};
                    
                    await updateDoc(doc(db, 'users', uid), newData);

                    if (imageFile) {
                        await uploadImage(`users/${uid}`, imageFile);
                    }

                    enqueueSnackbar('User created', {variant: 'success'});

                    navigate(-1);
                }
            } else {
                const {id} = newData;
                const docRef = doc(db, 'users', id);

                if (email && Object.keys(dirtyFields).includes('email')) {
                    await updateUserEmail({uid: id, email, throws: false});
                }

                if (password && Object.keys(dirtyFields).includes('password')) {
                    await updateUserPassword({uid: id, password, throws: false});
                }

                let image;
                if (imageFile) {
                    image = await uploadImage(`users/${id}`, imageFile);
                } else if (imageFile === null) {
                    image = null;
                }
    
                await updateDoc(docRef, {
                    ...newData,
                    image
                });

                reset(ensureMomentDates({
                    ...newData,
                    image,
                    password: null,
                    confirmPassword: null
                }));

                setLoading(false);
                setEditingEmail(false);

                enqueueSnackbar(`${isProfile ? 'Profile' : 'User'} updated`, {variant: 'success'});

                navigate(-1);
            }
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [db, navigate, updateUserPassword, currentUserIsAdmin, enqueueSnackbar, imageFile, currentUser, isProfile, isNew, dirtyFields]);

    const tabs = [
        {key: 'edit', label: isNew ? 'New User' : isProfile ? 'Edit Profile' : 'Edit User'}
    ];

    if (!isNew) {
        tabs.push({key: 'qualifications', label: 'Qualifications'});
    }

    const handleTabChange = useCallback((event, index) => {
        const base = isProfile ? '/profile' : `/users/${uid}`;
        const {key} = tabs[index] || {};

        if (key === 'edit') {
            navigate(`${base}`);
        } else {
            navigate(`${base}/${key}`);
        }
    }, [navigate, uid, isProfile]);

    if (usesJIBC) {
        tabs.push({key: 'transcript', label: 'JIBC Transcript'});

        if (hasPermission(currentUser, 'users.write')) {
            tabs.push({key: 'jibcConsent', label: 'JIBC Consent'});
        }
    }

    if (hasFeature('memberStatistics')) {
        tabs.push({key: 'statistics', label: 'Statistics'});
    }

    if (currentUserIsAdmin && !isProfile) {
        tabs.push({key: 'permissions', label: 'Permissions'});
    }

    return (
        <Box>
            <Typography variant="h5" gutterBottom>{isNew ? 'New User' : (loading ? <Skeleton width={180} /> : (fullName || email))}</Typography>

            {!isNew && (
                <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 2}}>
                    <Tabs value={tabs.findIndex(tab => tab.key === activeTab)} onChange={handleTabChange}>
                        {tabs.map(tab => (
                            <Tab key={tab.key} label={tab.label} />
                        ))}
                    </Tabs>
                </Box>
            )}
            {activeTab === 'edit' && (
                <FormProvider {...methods}>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                        <Grid container spacing={2}>
                            {!isNew && (
                                <Grid size={{xs: 12, sm: 4}}>
                                    <ImageUploadField name="image" />

                                    {!isProfile && (
                                        <Paper sx={{mt: 1, p: 1}}>
                                            <Typography variant="caption" sx={{mt: 2}}>Last Sign In: <strong>{lastSignIn ? lastSignIn.fromNow() : 'Never'}</strong></Typography>
                                        </Paper>
                                    )}
                                </Grid>
                            )}
                            <Grid size={{xs: 12, sm: isNew ? 12 : 8}}>
                                <Grid container spacing={1}>
                                    <Grid size={(!isNew && !emailVerified) ? 'grow' : 12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            autoComplete="email"
                                            disabled={!editingEmail || loading}
                                            rules={{
                                                required: true,
                                                pattern
                                            }}
                                            slotProps={{
                                                ...!editingEmail && {
                                                    input: {
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={() => setEditingEmail(!editingEmail)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }
                                                }
                                            }}
                                        />
                                    </Grid>
                                    {!isNew && !emailVerified && (
                                        <Grid size="auto" sx={{display: 'flex', alignItems: 'stretch'}}>
                                            <LoadingButton onClick={handleEmailVerification} loading={sendingEmailVerification} variant="contained" sx={{width: '100%'}}>Send Verification Email</LoadingButton>
                                        </Grid>
                                    )}
                                </Grid>

                                {(isNew || (currentUserIsAdmin && registered) || isProfile) && (
                                    <PasswordField
                                        sx={{mt: 2}}
                                        fullWidth
                                        label="New Password"
                                        name="password"
                                        autoComplete="new-password"
                                        disabled={loading}
                                    />
                                )}
                                {password && (
                                    <>
                                        <PasswordField
                                            sx={{mt: 2}}
                                            rules={{
                                                required: true,
                                                validate: value => value !== password ? 'Passwords do not match' : true
                                            }}
                                            fullWidth
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            autoComplete="new-password"
                                            disabled={loading}
                                        />
                                    </>
                                )}
                                
                                <Grid container spacing={1} sx={{mt: 1}}>
                                    {hasPermission(currentUser, 'users.write') && (
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <SelectField
                                                sx={{mt: 1}}
                                                margin="normal"
                                                fullWidth
                                                label="Rank"
                                                name="role"
                                                allowBlank
                                                disabled={loading}
                                                options={Object.keys(Ranks).map(value => {
                                                    return {
                                                        value,
                                                        label: Ranks[value]
                                                    };
                                                })}
                                            />
                                        </Grid>
                                    )}
                                    <Grid size={{xs: 12, sm: 6}}>
                                        <StationsField
                                            sx={{mt: 1}}
                                            fullWidth
                                            allowBlank
                                            disabled={loading}
                                        />
                                    </Grid>
                                </Grid>

                                <Typography variant="h6" sx={{mt: 2, mb: 1}}>Personal Information</Typography>
                                <Divider />

                                <Grid container spacing={1} sx={{pt: 1}}>
                                    <Grid size={{xs: 12, sm: 6}}>
                                        <TextField
                                            sx={{mt: 1}}
                                            fullWidth
                                            label="First Name"
                                            name="firstName"
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6}}>
                                        <TextField
                                            sx={{mt: 1}}
                                            fullWidth
                                            label="Last Name"
                                            name="lastName"
                                            disabled={loading}
                                        />
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6}}>
                                        <DatePickerField
                                            sx={{mt: 1}}
                                            label="Date of Birth"
                                            name="dob"
                                            fullWidth
                                            disabled={loading}
                                        />
                                    </Grid>

                                    {(hasPermission(currentUser, 'users.write') || isProfile) && (
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <PhoneField
                                                sx={{mt: 1}}
                                                fullWidth
                                                label="Phone"
                                                name="phone"
                                                disabled={loading}
                                            />
                                        </Grid>
                                    )}

                                    {currentUserIsAdmin && (
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <TextField
                                                sx={{mt: 1}}
                                                fullWidth
                                                label="Employee ID"
                                                name="employeeId"
                                                disabled={loading}
                                            />
                                        </Grid>
                                    )}

                                    {usesJIBC && (
                                        <Grid size={{xs: 12, sm: 6}}>
                                            <TextField
                                                sx={{mt: 1}}
                                                fullWidth
                                                label="JIBC #"
                                                name="jibcNumber"
                                                disabled={loading}
                                            />
                                        </Grid>
                                    )}
                                </Grid>

                                {(isProfile || currentUserIsAdmin) && (
                                    <>
                                        <Typography variant="h6" sx={{mt: 2, mb: 1}}>Settings</Typography>
                                        <Divider />

                                        <Grid container spacing={1}>
                                            <Grid size={{xs: 12, sm: 4}}>
                                                <TextField
                                                    sx={{mt: 1}}
                                                    label="Date Format"
                                                    name="settings.dateFormat"
                                                    placeholder="DD/MM/yyyy"
                                                    fullWidth
                                                    disabled={loading}
                                                    helperText={moment().format(dateFormat || 'DD/MM/YYYY')}
                                                />
                                            </Grid>

                                            <Grid size={{xs: 12, sm: 4}}>
                                                <TextField
                                                    sx={{mt: 1}}
                                                    label="Short Date Format"
                                                    name="settings.dateFormatShort"
                                                    placeholder="MMMM yyyy"
                                                    fullWidth
                                                    disabled={loading}
                                                    helperText={moment().format(dateFormatShort || 'MMMM YYYY')}
                                                />
                                            </Grid>

                                            <Grid size={{xs: 12, sm: 4}}>
                                                <TextField
                                                    sx={{mt: 1}}
                                                    label="Long Date Format"
                                                    name="settings.dateFormatLong"
                                                    placeholder="LLL"
                                                    fullWidth
                                                    disabled={loading}
                                                    helperText={moment().format(dateFormatLong || 'LLL')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}

                                {currentUserIsAdmin && (
                                    <>
                                        <Typography variant="h6" sx={{mt: 2, mb: 1}}>Administration</Typography>
                                        <Divider />

                                        <CheckboxField sx={{mr: 1}} name="deactivated" label="No longer active?" />
                                    </>
                                )}

                                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                                    {(currentUserIsAdmin && !isCurrentUser) && (
                                        <LoadingButton
                                            size="large"
                                            onClick={handleImpersonate}
                                            disabled={impersonating}
                                            loading={impersonating}
                                            sx={{mr: 1}}
                                        >
                                            Impersonate
                                        </LoadingButton>
                                    )}

                                    {(hasPermission(currentUser, 'users.delete') && !isProfile && !isNew && !isCurrentUser) && (
                                        <LoadingButton
                                            size="large"
                                            onClick={handleDelete}
                                            disabled={loading || deleting}
                                            loading={deleting}
                                            sx={{mr: 1}}
                                        >
                                            Delete User
                                        </LoadingButton>
                                    )}

                                    <LoadingButton
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        onClick={handleSubmit(onSubmit)}
                                        disabled={loading || deleting}
                                        loading={loading || deleting}
                                    >
                                        {isProfile ? 'Save Profile' : (isNew ? 'Create User' : 'Update User')}
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </FormProvider>
            )}
            {activeTab === 'qualifications' && (
                <Qualifications user={user} />
            )}
            {activeTab === 'transcript' && (
                <JIBCTranscript user={user} />
            )}
            {activeTab === 'jibcConsent' && (
                <JIBCConsent user={user} />
            )}
            {activeTab === 'statistics' && (
                <Statistics user={user} />
            )}
            {(!isProfile && activeTab === 'permissions') && (
                <Permissions user={user} />
            )}
        </Box>
    );
}