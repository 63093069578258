import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Box, Stack, Alert, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import {useSnackbar} from 'notistack';
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import {SingleInputDateRangeField} from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import {collection, query, where} from 'firebase/firestore';
import moment from 'moment';
import {useForm, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {httpsCallable} from 'firebase/functions';
import {get} from 'lodash';
import {useGridApiRef} from '@mui/x-data-grid-pro';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import {db, functions} from '-/firebase';
import {UserContext} from '-/contexts/User';
import {SettingsContext} from '-/contexts/Settings';

import {hasPermission, getCollection, populateUsers, populateKeyFromCollection} from '-/data/utils';

import TrainingGrid from './training/Grid';
import Statistics from './training/Stats';

// import VSSync from '-/dialogs/VSSync';

import Filter from '-/components/Filter';
import Import from './training/Import';

const ImportFromIAR = props => {
    const [importing, setImporting] = useState(false);
    const [training, setTraining] = useState([]);
    const [open, setOpen] = useState(false);
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [dateRange, setDateRange] = useState([props.startDate ? moment(props.startDate) : moment().subtract(1, 'month'), moment()]);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const importTrainingFromIAR = httpsCallable(functions, 'importTrainingFromIAR');
    const {currentUser} = useContext(UserContext);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';

    useEffect(() => {
        if (props.startDate) {
            setDateRange([moment(props.startDate), moment()]);
        }
    }, [props.startDate]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleImportClose = () => {
        setImportDialogOpen(false);
    };

    const handleImport = useCallback(async() => {
        setImporting(true);

        try {
            const [startDate, endDate] = dateRange;
            const {data} = await importTrainingFromIAR({
                startDate: startDate.toDate(),
                endDate: endDate.toDate()
            });

            const {training = []} = data;
            // incidents = await populateKeyFromCollection(db, incidents, 'station', 'stations');
            // incidents = await populateKeyFromCollection(db, incidents, 'apparatus', 'apparatus');
            // incidents = populateDocsWithUsers(incidents, users);
            setTraining(training.map(training => {
                const {date, suggestedTypes: types, suggestedInstructors: instructors, ...rest} = training;

                return {
                    ...rest,
                    date: new Date(date),
                    types,
                    instructors
                };
            }));

            setOpen(false);
            setImportDialogOpen(true);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setImporting(false);
    }, [importTrainingFromIAR, enqueueSnackbar, navigate]);

    return (
        <>
            <LoadingButton startIcon={<SyncIcon />} loading={importing} variant="outlined" sx={{mr: 1}} onClick={() => setOpen(true)}>
                Import from IaR
            </LoadingButton>

            <Import training={training} open={importDialogOpen} onClose={handleImportClose} />

            <Dialog
                open={open}
                onClose={importing ? null : handleClose}
                fullWidth
                maxWidth="sm"
                disableEscapeKeyDown={importing}
            >
                <DialogTitle>Import from IamResponding</DialogTitle>
                <DialogContent sx={{display: 'flex'}}>
                    <DateRangePicker
                        sx={{mt: 2, flex: 1}}
                        format={dateFormat}
                        label="Date Range"
                        disabled={importing}
                        slots={{field: SingleInputDateRangeField}}
                        value={dateRange}
                        onChange={range => setDateRange(range)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={importing} onClick={handleClose}>Cancel</Button>
                    <LoadingButton loading={importing} variant="contained" type="submit" onClick={handleImport}>Start Import</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

const Training = () => {
    const {currentUser} = useContext(UserContext);
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [training, setTraining] = useState([]);
    const [filtered, setFiltered] = useState(false);
    const [expanded, setExpanded] = useState(!hasPermission(currentUser, 'training.read'));
    const {enqueueSnackbar} = useSnackbar();
    const apiRef = useGridApiRef();
    const [latestTrainingDate, setLatestTrainingDate] = useState(null);
    const {usesIAR} = useContext(SettingsContext);
    const navigate = useNavigate();

    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    let defaultDateRange = [moment().startOf('year'), moment().endOf('year')];
    if (startDate && endDate) {
        defaultDateRange = [moment(startDate), moment(endDate)];
    }

    const methods = useForm({
        defaultValues: {
            dateRange: defaultDateRange
        },
        mode: 'onChange'
    });

    const {watch} = methods;
    const dateRange = watch('dateRange');

    useEffect(() => {
        if (dateRange) {
            const [startDate, endDate] = dateRange;

            // if startDate and endDate are the default values, we dont need to set search params
            if (startDate.isSame(defaultDateRange[0]) && endDate.isSame(defaultDateRange[1])) {
                return;
            }

            setSearchParams({
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD')
            });
        }
    }, [dateRange]);

    const fetch = useCallback(async() => {
        setLoading(true);

        try {
            let [startDate, endDate] = dateRange || [];
            startDate = moment(startDate).startOf('day').toDate();
            endDate = moment(endDate).endOf('day').toDate();

            const ref = collection(db, 'training');
            const q = query(ref, where('date', '>=', startDate), where('date', '<=', endDate));
            let training = await getCollection(db, q);
            training = await populateUsers(db, training);
            training = await populateKeyFromCollection(db, training, 'types', 'trainingTypes');

            const orderedTraining = training.sort((a, b) => {
                return moment(b.date).diff(moment(a.date));
            });

            if (orderedTraining.length) {
                setLatestTrainingDate(orderedTraining[0].date);
            }

            setTraining(training);

            // setTimeout(() => {
            //     if (!apiRef || !apiRef.current) {
            //         return;
            //     }
                
            //     apiRef.current.autosizeColumns({
            //         columns: [
            //             'instructors',
            //             'type',
            //             'raw.type',
            //             'date'
            //         ],
            //         includeHeaders: true,
            //         // includeOutliers: true,
            //         expand: true
            //     });
            // }, 250);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [dateRange]);

    useEffect(() => {
        fetch();
    }, [searchParams]);

    // const handleSyncClose = useCallback(async newTraining => {
    //     setDialogOpen(false);

    //     if (newTraining) {
    //         setTraining(training.map(training => {
    //             if (training.uid === newTraining.uid) {
    //                 return {
    //                     ...training,
    //                     ...newTraining
    //                 };
    //             }

    //             return training;
    //         }));
    //     }
    // }, [training]);

    const columns = filtered ? [
        {
            field: 'attending',
            headerName: 'Attended',
            type: 'attended',
            width: 100,
            valueGetter: (value, row) => {
                const {absent} = row || {};
                return !absent;
            },
            renderCell: params => {
                const {value} = params || {};

                if (value) {
                    return (
                        <Stack sx={{width: '100%', alignItems: 'center'}}>
                            <CheckIcon />
                        </Stack>
                    );
                }

                return null;
            }
        }
    ] : [];

    const handleFilter = (field, label, activeFilter, filteredRows) => {
        if (!filtered || label !== filtered.label) {
            setFiltered({
                field,
                label,
                activeFilter,
                rows: filteredRows
            });

            setExpanded(false);

            return;
        }

        setFiltered(false);
    };

    return (
        <FormProvider {...methods}>
            {/* {hasPermission(currentUser) && !!dialogOpen && (
                <VSSync open={!!dialogOpen} {...dialogOpen} onClose={handleSyncClose} />
            )} */}

            <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
                <Typography variant="h5" sx={{flex: 1, mr: 1}}>Training Records</Typography>
            
                <Filter loading={loading}>
                    {hasPermission(currentUser, 'training.write') && (
                        <>
                            <Button variant="outlined" sx={{mr: 1}} onClick={() => navigate('/training/add')} startIcon={<AddIcon />}>Add</Button>
                            {usesIAR && <ImportFromIAR startDate={latestTrainingDate} />}
                        </>
                    )}
                </Filter>
            </Box>

            {(training.length > 0 || hasPermission(currentUser, 'training.stats')) && (
                <Statistics expanded={expanded} onExpandedChange={setExpanded} training={training} activeFilter={filtered?.activeFilter} onFilter={handleFilter} />
            )}

            {filtered && (
                <Alert severity="info" sx={{mb: 2, mt: 1}} action={<Button color="inherit" size="small" onClick={() => setFiltered(false)}>Clear</Button>}>
                    Filtered by <strong>{filtered.label}</strong>
                </Alert>
            )}
            
            {hasPermission(currentUser, 'training.read') && (
                <TrainingGrid
                    apiRef={apiRef}
                    stateId="training"
                    training={filtered ? filtered.rows : training}
                    loading={loading}
                    columns={columns}
                />
            )}
        </FormProvider>
    );
};

export default Training;