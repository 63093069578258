import React, {useState, useEffect, useMemo, useContext, useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Grid2 as Grid, Button, IconButton, Box, Typography, Paper, Skeleton} from '@mui/material';
import {useSnackbar} from 'notistack';
import {doc, updateDoc, deleteDoc, onSnapshot} from 'firebase/firestore';
import {get, omit} from 'lodash';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import {ref, getDownloadURL} from 'firebase/storage';

import {db, storage} from '-/firebase';
import {UserContext} from '-/contexts/User';

import useDocumentTitle from '-/hooks/useDocumentTitle';

import {getCollectionDoc, processRawDoc, ensureJSDates, ensureMomentDates, hasPermission, uploadImage} from '-/data/utils';

import RecordsGrid from './gear/RecordsGrid';
import ItemDialog from './gear/ItemDialog';

const HeaderItem = ({label, value, loading, ...rest}) => (
    <Grid {...rest} size={{xs: 12, md: 6, lr: 4}}>
        <Typography variant="caption" color="text.secondary">{loading ? <Skeleton /> : label}</Typography>
        <Typography variant="h6">{loading ? <Skeleton /> : value}</Typography>
    </Grid>
);

export default function GearItem() {
    const params = useParams();
    const {id: uid, ...otherParams} = params;
    const [loadingItem, setLoadingItem] = useState(true);
    const [loadingParent, setLoadingParent] = useState(true);
    const [editing, setEditing] = useState(false);
    const [parent, setParent] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [item, setItem] = useState(null);

    const loading = loadingItem || loadingParent;

    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';

    const {name, image = []} = item || {};

    const {additionalFields = []} = parent || {};

    const deleteUrl = useMemo(() => {
        let parts = [];

        Object.entries(otherParams).forEach(([key, value]) => {
            if (key !== '*' && value) {
                parts.push('gear', value);
            }
        });

        return `/${parts.join('/')}`;
    }, [otherParams]);

    const parentRefArgs = useMemo(() => {
        const otherParams = omit(params, 'id');
        let refArgs = [db];

        Object.entries(otherParams).forEach(([key, param]) => {
            if (key !== '*' && param) {
                refArgs.push('gear', param);
            }
        });

        return refArgs;
    }, [db, params]);

    const refArgs = useMemo(() => {
        let refArgs = [...parentRefArgs];

        refArgs.push('items', uid);

        return refArgs;
    }, [uid, parentRefArgs]);

    useEffect(() => {
        setLoadingParent(true);

        const ref = doc(...parentRefArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            const row = processRawDoc(snapshot);

            setParent(row);
            setLoadingParent(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, uid, parentRefArgs]);

    useEffect(() => {
        setLoadingItem(true);

        const ref = doc(...refArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            const record = processRawDoc(snapshot);
            if (!record) {
                return;
            }

            const result = ensureMomentDates(record);
            for (const field of additionalFields) {
                const {id, type} = field;
                const value = record[id];

                if (type === 'user') {
                    const user = await getCollectionDoc(db, 'users', value);
                    result[id] = user;
                } else if (type === 'apparatus') {
                    const apparatus = await getCollectionDoc(db, 'apparatus', value);
                    result[id] = apparatus;
                }
            }

            setItem(result);

            setLoadingItem(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, uid, refArgs, parent]);

    const onUpdate = useCallback(async data => {
        setLoadingItem(true);

        const {imageFile, image = [], imageDeleted = [], ...rest} = data;

        if (imageDeleted.length) {
            await updateDoc(doc(...refArgs), ensureJSDates({
                image: image.filter(({id}) => !imageDeleted.some(d => d.id === id))
            }));
        }

        const path = refArgs.slice(1).join('/');
        if (imageFile) {
            await Promise.all(imageFile.map(async file => {
                return await uploadImage(path, file, true);
            }));
        }

        const toUpdate = ensureJSDates({
            ...rest
        });

        if (Object.keys(toUpdate)) {
            const update = {
                ...toUpdate,
                updatedAt: new Date()
            };

            for (const field of additionalFields) {
                const {id, type} = field;
                const value = update[id];

                if (type !== 'date' && typeof value === 'object') {
                    update[id] = value?.uid || null;
                }
            }

            await updateDoc(doc(...refArgs), update);
        }

        setEditing(false);
        setLoadingItem(false);
    }, [parent]);

    const handleDelete = () => {
        const onDelete = async() => {
            setLoadingItem(true);

            const ref = doc(...refArgs);
            await deleteDoc(ref);

            navigate(deleteUrl);
        };

        enqueueSnackbar('Are you sure you want to delete this item?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    };

    useDocumentTitle(name || 'Item');

    useEffect(() => {
        const [firstImage] = image;
        const {filePath, thumbnailPath} = firstImage || {};

        let isSubscribed = true;

        const fetch = async() => {
            try {
                if (thumbnailPath) {
                    const url = await getDownloadURL(ref(storage, thumbnailPath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }

                if (filePath) {
                    const url = await getDownloadURL(ref(storage, filePath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }
            } catch(e) {
                console.warn(e);
            }
        };

        setImageUrl(null);
        fetch();

        return () => isSubscribed = false;
    }, [image]);

    return (
        <Box>
            {hasPermission(currentUser, 'gear.write') && (
                <ItemDialog parent={parent} item={item} onSubmit={onUpdate} onDelete={handleDelete} open={editing} handleClose={() => setEditing(false)} />
            )}
            
            <Paper variant="outlined" sx={{p: 2, position: 'relative', mb: 1, minHeight: 55}}>
                <Grid spacing={2} container>
                    {imageUrl && (
                        <Grid
                            size={{xs: 12, md: 4}}
                            sx={{aspectRatio: '4/3', borderRadius: 1, backgroundImage: `url(${imageUrl})`, backgroundPosition: 'center', backgroundSize: 'cover'}}
                            onClick={() => window.open(imageUrl, '_blank')}
                        />
                    )}

                    <Grid size={{xs: 12, md: imageUrl ? 8 : 12}} spacing={1} container>
                        {additionalFields.map((field, index) => {
                            const {id, label, type} = field;
                            const rawValue = (item || {})[id];
                            let value;

                            if (rawValue) {
                                if (type === 'date') {
                                    value = moment(rawValue).format(dateFormat);
                                } else if (type === 'apparatus') {
                                    const {tag} = rawValue || {};
                                    value = tag || '-';
                                } else if (type === 'user') {
                                    const {fullName, email} = rawValue || {};
                                    value = fullName || email || '-';
                                } else {
                                    value = rawValue;
                                }
                            }

                            return (
                                <HeaderItem key={`additional-fields-${index}`} label={label} loading={loading} value={value || '-'} />
                            );
                        })}
                    </Grid>
                </Grid>
            
                {hasPermission(currentUser, 'gear.write') && (
                    <IconButton disabled={loading} sx={{ml: 1, position: 'absolute', bottom: 10, right: 10}} onClick={() => setEditing(true)}>
                        <EditIcon />
                    </IconButton>
                )}
            </Paper>

            {/* <TasksGrid sx={{mt: 3}} /> */}

            <RecordsGrid sx={{mt: 3}} />
        </Box>
    );
};