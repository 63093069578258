import React, {useContext, useState, useCallback, useEffect} from 'react';
import {Alert, Button, Grid2 as Grid, Typography, Card} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {httpsCallable} from 'firebase/functions';
import {useForm, FormProvider} from 'react-hook-form';
import {flatten, unflatten} from 'flat';
import {useSnackbar} from 'notistack';
import {DefaultPermissions, Permissions} from '@embertracking/common';

import {functions} from '-/firebase';

import {UserContext} from '-/contexts/User';

import CheckboxField from '-/form/CheckboxField';

export default function User(props) {
    const {user = {}} = props;
    const {uid} = user;
    const {currentUser} = useContext(UserContext);
    const {enqueueSnackbar} = useSnackbar();

    const isCurrentUser = currentUser?.uid === uid;

    const [loading, setLoading] = useState(true);
    const getUserPermissions = httpsCallable(functions, 'getUserPermissions');
    const updateUserPermissions = httpsCallable(functions, 'updateUserPermissions');

    const defaultValues = flatten({
        permissions: DefaultPermissions
    });

    const methods = useForm({
        mode: 'onChange'
    });
    const {handleSubmit, reset, setValue, watch, formState} = methods;
    const {isDirty} = formState;

    const admin = watch('permissions.admin');

    const handleReset = () => {
        const {permissions} = unflatten(defaultValues || {});

        setValue('permissions', {
            ...permissions,
            admin
        }, {shouldDirty: true});
    };

    const fetch = useCallback(async() => {
        if (uid) {
            setLoading(true);

            try {
                const {data} = await getUserPermissions({uid});
                const {permissions: keys} = data || {};

                const permissions = unflatten(keys);

                reset({
                    permissions
                });

                setLoading(false);
            } catch(e) {
                enqueueSnackbar('Failed to fetch permissions', {variant: 'error'});
            }
        }
    }, [uid]);

    useEffect(() => {
        if (uid) {
            fetch();
        }
    }, [uid]);

    const onSubmit = async data => {
        const {permissions} = data;

        setLoading(true);

        const flattened = flatten(permissions);

        try {
            await updateUserPermissions({uid, permissions: flattened});

            enqueueSnackbar('Permissions updated', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar('Failed to update permissions', {variant: 'error'});
        }

        setLoading(false);
    };

    return (
        <FormProvider {...methods}>
            {(admin && isCurrentUser) && (
                <Alert sx={{mb: 2}} severity="warning">You cannot change your own permissions</Alert>
            )}

            <Grid container spacing={1}>
                <Grid size={12}>
                    <Card sx={{px: 2, py: 1}}>
                        <CheckboxField
                            name="permissions.admin"
                            label="Is Administrator?"
                            disabled={loading || isCurrentUser}
                            helperText="Grants full access to all features and permissions"
                        />
                    </Card>
                </Grid>

                <Grid size={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant="contained" onClick={handleReset} disabled={admin || loading}>Reset Permissions</Button>
                </Grid>
                        
                {Object.keys(Permissions).map(group => {
                    const {label, permissions = {}} = Permissions[group] || {};

                    return (
                        <Grid size={12} key={group}>
                            <Card sx={{px: 2, py: 1}}>
                                <Typography variant="h6">{label}</Typography>

                                <Grid container spacing={2}>
                                    {Object.keys(permissions).map(permission => (
                                        <Grid size={{xs: 12, sm: 6, md: 4}} key={`${group}.${permission}`}>
                                            <CheckboxField
                                                fullWidth
                                                name={`permissions.${group}.${permission}`}
                                                label={permission}
                                                disabled={loading || admin}
                                                {...admin && {checked: true}}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Card>
                        </Grid>
                    );
                })}

                <Grid size={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <LoadingButton
                        type="submit"
                        size="large"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                        disabled={(admin && isCurrentUser) || loading || !isDirty}
                        loading={loading}
                    >
                        Update Permissions
                    </LoadingButton>
                </Grid>
            </Grid>
        </FormProvider>
    );
}