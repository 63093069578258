import React, {useState, useEffect, useContext} from 'react';
import {TextField, Autocomplete, Chip, Skeleton} from '@mui/material';
import {useController} from 'react-hook-form';
import {capitalize, sortBy, isString} from 'lodash';
import {useSnackbar} from 'notistack';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import {UserContext} from '-/contexts/User';
import {db} from '-/firebase';

import {getCollection, hasPermission} from '-/data/utils';

export default function ControlledAutocompleteField(props) {
    const {name, label, rules: rawRules, required, helperText, limitToStation, multiple = true, ...rest} = props;
    const fieldLabel = label !== false ? label || capitalize(name) : null;
    const rules = {...rawRules};
    if (required && !rules.required) {
        rules.required = fieldLabel ? `${fieldLabel} is required` : 'This field is required';
    }

    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const {currentUser} = useContext(UserContext);
    const {station} = currentUser;
    const {enqueueSnackbar} = useSnackbar();
    
    useEffect(() => {
        let isSubscribed = true;

        async function fetch() {
            try {
                let docs = await getCollection(db, 'users', {useCache: true});
                if (limitToStation && !hasPermission(currentUser) && station) {
                    docs = docs.filter(doc => doc.station === currentUser.station);
                }

                docs = docs.filter(doc => doc.deactivated !== true);

                if (isSubscribed) {
                    setOptions(sortBy(docs.filter(doc => {
                        return doc.role !== 'STATION';
                    }), ['lastName', 'firstName']));
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, currentUser, limitToStation]);

    const {field, fieldState: {error}} = useController({name, rules});
    const {value, onChange, ...restField} = field;

    return (
        <Autocomplete
            {...rest}
            {...restField}
            loading={loading}
            multiple={multiple}
            value={value || []}
            options={options}
            onChange={(e, newValue) => {
                if (!multiple) {
                    if (!newValue) {
                        onChange(null);
                        return;
                    }

                    onChange(isString(newValue) ? newValue : newValue.uid);
                    return;
                }

                onChange(newValue.map(v => {
                    if (isString(v)) {
                        return v;
                    }

                    return v.uid;
                }));
            }}
            getOptionLabel={option => {
                if (isString(option)) {
                    option = options.find(o => o.uid === option);
                }

                const {fullName, email} = option || {};
                return fullName || email || '';
            }}
            isOptionEqualToValue={(option, value) => {
                if (value) {
                    if (isString(value)) {
                        return option.uid === value;
                    }

                    return option.uid === value.uid;
                }

                return false;
            }}
            onInputChange={(e, newInputValue) => {
                if (e && newInputValue) {
                    setOpen(true);
                }
            }}
            // onFocus={() => {
            //     setOpen(true);
            // }}
            filterSelectedOptions
            renderInput={params => (
                <TextField
                    name={name}
                    {...params}
                    error={!!error}
                    helperText={error ? error.message : helperText}
                    onClick={() => {
                        setOpen(!open);
                    }}
                    label={label}
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    const {key, ...tagProps} = getTagProps({index});
                    const user = typeof option === 'string' ? options.find(o => o.uid === option) : option;
                    const {fullName, email} = user || {};

                    return (
                        <Chip label={(!loading || fullName || email) ? (fullName || email || 'Unknown') : <Skeleton width={100} />} key={key} {...tagProps} />
                    );
                })
            }
            renderOption={(props, option, {inputValue}) => {
                const {key, ...optionProps} = props;
                const {fullName, email} = option || {};
                const matches = match(fullName || email, inputValue, {insideWords: true});
                const parts = parse(fullName || email, matches);
        
                return (
                    <li key={key} {...optionProps}>
                        <div>
                            {parts.map((part, index) => (
                                <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
            open={open}
            onClose={e => {
                const {type} = e;

                if (type === 'blur') {
                    setOpen(false);
                }
            }}
        />
    );
};
