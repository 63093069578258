import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Box, Button, Paper} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {useParams} from 'react-router-dom';
import {useForm, FormProvider, useFieldArray, useFormContext} from 'react-hook-form';
import {useSnackbar} from 'notistack';
import {LoadingButton} from '@mui/lab';
import {collection, doc, onSnapshot, addDoc} from 'firebase/firestore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import HistoryIcon from '@mui/icons-material/History';
import SaveIcon from '@mui/icons-material/Save';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

import {UserContext} from '-/contexts/User';
import {db} from '-/firebase';

import TextField from '-/form/TextField.js';

const SectionChecks = props => {
    const {saving, loading, editing, sectionIndex} = props;
    const {control, setValue} = useFormContext();

    const {fields, append, remove, swap} = useFieldArray({
        control,
        name: `sections[${sectionIndex}].checks`
    });

    const handleRowUpdate = useCallback(async data => {
        const {index, ...rest} = data;

        setValue(`sections[${sectionIndex}].checks[${index}]`, rest);

        return data;
    }, [setValue, sectionIndex]);
    
    const handleRowAdd = useCallback(() => {
        append({
            text: ''
        });
    }, [append]);

    const handleRowRemove = useCallback(index => {
        remove(index);
    }, [remove]);

    const handleRowOrderChange = useCallback(change => {
        const {oldIndex, targetIndex} = change;
        swap(oldIndex, targetIndex);
    }, [swap]);

    const columns = [
        {
            field: 'text',
            headerName: 'Item to Check',
            flex: 3,
            sortable: false,
            editable: editing
        },
        {
            field: 'qty',
            headerName: 'QTY',
            flex: 1,
            sortable: false,
            editable: editing
        }
    ];

    if (editing) {
        columns.push({
            field: 'actions',
            headerName: '',
            type: 'actions',
            editable: false,
            renderCell: params => {
                const {row} = params;
                const {index} = row;

                return (
                    <LoadingButton
                        color="primary"
                        size="small"
                        onClick={() => handleRowRemove(index)}
                    >
                        Delete
                    </LoadingButton>
                );
            },
            disableClickEventBubbling: true
        });
    }
    
    return (
        <Box sx={{flex: 1}}>
            <DataGridPro
                sx={{mt: 2}}
                rowReordering={editing}
                onRowOrderChange={handleRowOrderChange}
                hideFooter
                loading={loading}
                autoHeight
                rows={fields.map((field, index) => ({...field, index}))}
                columns={columns}
                editMode="row"
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                processRowUpdate={handleRowUpdate}
                experimentalFeatures={{newEditingApi: true}}
            />

            {editing && (
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    <LoadingButton
                        variant="outlined"
                        sx={{mt: 2}}
                        onClick={handleRowAdd}
                        disabled={saving || loading}
                        loading={saving}
                        startIcon={<AddIcon />}
                    >
                        Add New Row
                    </LoadingButton>
                </Box>
            )}
        </Box>
    );
};

const Sections = props => {
    const {saving, loading, editing} = props;
    const {control} = useFormContext();

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'sections'
    });

    const handleSectionAdd = useCallback(async() => {
        append({
            name: '',
            checks: []
        });
    }, [append]);

    return (
        <Box>
            {fields.map((field, index) => {
                const {id} = field;

                return (
                    <Paper key={`secton-${id}`} sx={{flex: 1, mb: 2, p: 2}} elevation={1}>
                        <TextField
                            fullWidth
                            disabled={!editing}
                            label="Section Name"
                            name={`sections.${index}.name`}
                        />

                        <SectionChecks {...props} sectionIndex={index} />

                        {editing && (
                            <Box sx={{display: 'flex', flex: 1, mt: 2, flexDirection: 'column', alignItems: 'flex-end'}}>
                                <LoadingButton
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => remove(index)}
                                    startIcon={<RemoveIcon />}
                                >
                                    Remove Section
                                </LoadingButton>
                            </Box>
                        )}
                    </Paper>
                );
            })}

            {editing && (
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    <LoadingButton
                        variant="contained"
                        onClick={handleSectionAdd}
                        disabled={saving || loading}
                        loading={saving}
                        startIcon={<AddIcon />}
                    >
                        Add New Section
                    </LoadingButton>
                </Box>
            )}
        </Box>
    );
};

const Checks = () => {
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [saving, setSaving] = useState(false);
    const [editing, setEditing] = useState(false);
    const [activeRecord, setActiveRecord] = useState(null); // eslint-disable-line
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const {id: uid, checkId: checkUid} = useParams();

    const methods = useForm({
        defaultValues: {
            checks: []
        },
        mode: 'onChange'
    });
    const {reset, handleSubmit} = methods;

    let isSubscribed = true;

    const handleFetch = useCallback(async () => {
    //     try {
    //         let newCheckRecord = {
    //             id: checkRecordRaw.id,
    //             uid: checkRecordRaw.id,
    //             ...checkRecordRaw.data()
    //         };

        //         newCheckRecord = await populateUser(db, newCheckRecord, true);

        //         const {users = [], loggedChecks = []} = newCheckRecord;
        //         const newChecks = loggedChecks;

        //         const newNotes = loggedChecks.map(check => check.note || '');
        //         const newCompleted = loggedChecks.map(check => check.completed || false);
        //         const newSkipped = loggedChecks.map(check => check.skipped || false);

        //         const canSave = newCheckRecord.active && newChecks.length && calculateExpandedIndex(newChecks, newCompleted, newSkipped) === -1;

        //         if (isSubscribed) {
        //             if (newCheckRecord.active === false && checkRecord.active === true) {
        //                 navigate(`/${uid}/weekly`);
        //                 return;
        //             }

        //             setCheckRecord(newCheckRecord);
        //             setCanSave(canSave);

        //             setChecks(newChecks);
        //             setCompleted(newCompleted);
        //             setSkipped(newSkipped);
        //             setNotes(newNotes);
        //             reset({
        //                 members: users.map(user => user.id)
        //             });

        //             if (firstLoad) {
        //                 setExpandedIndex(calculateExpandedIndex(newChecks, newCompleted, newSkipped));
        //                 firstLoad = false;

        //                 console.log('done! firstLoad', firstLoad);
        //             }
        //         }
        //     } catch(e) {
        //         enqueueSnackbar(e.message, {variant: 'error'});
        //     }

        if (isSubscribed) {
            setLoading(false);
        }
    }, [isSubscribed]);

    useEffect(() => {
        const ref = doc(db, 'apparatus', uid, 'checks', checkUid);
        const snapshot = onSnapshot(ref, handleFetch);
        
        return () => {
            snapshot();
            isSubscribed = false;
        };
    }, [enqueueSnackbar, db, uid]);

    const fetch = useCallback(async() => {
        // try {
        //     const ref = collection(db, 'checks');
        //     const q = query(ref, where('apparatus', '==', uid), where('type', '==', 'full'), orderBy('createdAt', 'desc'), limit(25));
        //     const docRef = doc(db, 'apparatus', uid, 'checks', checkRecord.uid);
        //     const raw = await getDocs(q);
        //     let docs = [];

        //     raw.forEach(doc => {
        //         const data = doc.data();

        //         docs.push({
        //             id: doc.id,
        //             uid: doc.id,
        //             ...data
        //         });
        //     });

        //     docs = await populateUsers(db, docs);

        //     if (isSubscribed) {
        //         const [latestDoc] = docs.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());

        //         setRecords(docs);

        //         setActiveRecord(latestDoc);
        //         reset(latestDoc);
        //     }
        // } catch(e) {
        //     enqueueSnackbar(e.message, {variant: 'error'});
        //     console.warn(e);
        // }

        // if (isSubscribed) {
        //     setLoading(false);
        // }
    }, [enqueueSnackbar, db, uid, isSubscribed, reset]);

    useEffect(() => {
        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, uid]);

    const handleSave = useCallback(async values => {
        const {sections} = values;

        try {
            setSaving(true);

            const docRef = collection(db, 'checks');
            await addDoc(docRef, {
                apparatus: uid,
                type: 'full',
                sections,
                createdAt: new Date(),
                user: currentUser.uid
            });

            setSaving(false);
            setEditing(false);

            await fetch();
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
            console.warn(e);

            setSaving(false);
        }
    }, [db, currentUser, enqueueSnackbar, uid, fetch]);

    const handleCancel = useCallback(async() => {
        setEditing(false);

        reset({
            ...activeRecord
        });
    }, [activeRecord, reset]);

    return (
        <FormProvider {...methods}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mb: 2}}>
                {editing ? (
                    <>
                        <Button startIcon={<DoDisturbIcon />} size="small" sx={{mr: 1}} onClick={handleCancel}>Cancel</Button>
                        <LoadingButton loading={saving} size="small" startIcon={<SaveIcon />} variant="contained" onClick={handleSubmit(handleSave)}>Save Version</LoadingButton>
                    </>
                ) : (
                    <Button startIcon={<HistoryIcon />} size="small" variant="contained" onClick={() => setEditing(true)}>Add Version</Button>
                )}
            </Box>

            <Sections editing={editing} />
        </FormProvider>
    );
};

export default Checks;