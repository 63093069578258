import React, {useState, useEffect, useContext} from 'react';
import {Grid2 as Grid, Alert, IconButton, Tooltip, Stack, AvatarGroup, Skeleton, Box, Backdrop, CircularProgress, Button, Divider, Typography, Card, CardMedia, useMediaQuery} from '@mui/material';
import {doc, getDoc, collection, query, limit, orderBy, where, addDoc, onSnapshot} from 'firebase/firestore';
import {useParams, useNavigate} from 'react-router-dom';
import HideImageIcon from '@mui/icons-material/HideImage';
import {ref, getDownloadURL} from 'firebase/storage';
import {DataGrid} from '@mui/x-data-grid';
import moment from 'moment';
import {get} from 'lodash';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {useSnackbar} from 'notistack';
import {useTheme, darken} from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FireTruckIcon from '@mui/icons-material/FireTruck';

import {UserContext} from '-/contexts/User';
import {db, storage} from '-/firebase';

import {processRawDocs, populateUsers, getCollection, hasPermission} from '-/data/utils';

import useDocumentTitle from '-/hooks/useDocumentTitle';

import UserAvatar from '-/components/UserAvatar';

const History = ({loading = true, rows = []}) => {
    const {id: uid} = useParams();

    const navigate = useNavigate();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const {currentUser} = useContext(UserContext);
    const dateFormatLong = get(currentUser, 'settings.dateFormatLong') || 'LLL';

    const columns = [
        {
            field: 'type',
            headerName: 'Type',
            width: 130,
            valueFormatter: value => {
                if (value === 'weekly') {
                    return 'Weekly Check';
                } else if (value === 'pretrips') {
                    return 'Pretrip';
                }

                return value;
            }
        },
        {
            field: 'updatedAt',
            headerName: 'Completed',
            sortable: true,
            flex: 1,
            valueFormatter: value => {
                if (!value) {
                    return '-';
                }

                value = value.toDate ? value.toDate() : value;
                
                return moment(value).format(isSmall ? 'MMM D, HH:mm' : dateFormatLong);
            }
        },
        {
            field: 'users',
            headerName: 'Members',
            renderCell: params => {
                const {row} = params;
                const {users = []} = row || {};

                if (!users || !users.length) {
                    return '-';
                }

                return (
                    <Box sx={{display: 'flex', height: '100%', alignItems: 'center'}}>
                        <AvatarGroup>
                            {users.map(user => {
                                const {uid} = user || {};

                                return (
                                    <UserAvatar key={`avatar-${uid}`} user={user} />
                                );
                            })}
                        </AvatarGroup>
                    </Box>
                );
            },
            flex: 1
        },
        {
            field: 'notes',
            headerName: 'Notes',
            width: isSmall ? 60 : 90,
            sortable: false,
            renderCell: params => {
                const {row} = params;
                const {notes, loggedChecks = []} = row;
                const hasNote = !!notes || loggedChecks.find(check => !!check.note);
                if (!hasNote) {
                    return;
                }

                return (
                    <Box sx={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <Tooltip title={notes || 'Has notes'}>
                            <WarningAmberIcon />
                        </Tooltip>
                    </Box>
                );
            }
        }
    ];

    return (
        <>
            <Divider sx={{my: 2}} />

            <Typography variant="h6" gutterBottom>Recent History</Typography>

            <Box sx={{display: 'flex'}}>
                <DataGrid
                    localeText={{
                        noRowsLabel: 'No recent history'
                    }}
                    hideFooter
                    loading={loading}
                    autoHeight
                    rowHeight={60}
                    rows={rows}
                    columns={columns}
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    onRowClick={params => {
                        const {row} = params;
                        const {uid: checkUid, type} = row;

                        navigate(`/apparatus/${uid}/${type}/${checkUid}`);
                    }}
                />
            </Box>
        </>
    );
};

export default function Apparatus() {
    const {id: uid} = useParams();
    const isNew = !uid;

    const [loading, setLoading] = useState(!isNew);
    const [record, setRecord] = useState(null);
    const [checks, setChecks] = useState([]);
    const [activeChecks, setActiveChecks] = useState([]);

    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const {isStation, uid: currentUserUid} = currentUser;
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            if (uid) {
                const ref = doc(db, 'apparatus', uid);
                const raw = await getDoc(ref);
                
                if (isSubscribed) {
                    if (!raw.exists()) {
                        navigate('/apparatus');
                    }

                    setRecord({
                        id: uid,
                        uid,
                        ...raw.data()
                    });

                    setLoading(false);
                }
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [db, uid, navigate]);

    useDocumentTitle(record ? record.tag : 'Apparatus');

    const {tag, color, image} = record || {};
    const [imageUrl, setImageUrl] = useState(null);

    let isSubscribed = true;

    const handleFetch = async raw => {
        try {
            // if (!isPretrip) {
            //     const ref = collection(db, 'checks');
            //     const q = query(ref, where('apparatus', '==', uid), where('type', '==', type), orderBy('createdAt', 'desc'), limit(1));
            //     const docs = await getCollection(db, q);

            //     const [record] = docs || [];
            //     if (!record) {
            //         navigate(-1);
            //         return;
            //     }
            // }

            let docs = processRawDocs(raw);
            docs = await populateUsers(db, docs);

            if (isSubscribed) {
                const activeChecks = docs.filter(doc => doc.active);
                const inactiveChecks = docs.filter(doc => !doc.active);

                setActiveChecks(activeChecks);
                setChecks(inactiveChecks);
            }
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        if (isSubscribed) {
            setLoading(false);
        }
    };

    useEffect(() => {
        const ref = collection(db, 'apparatus', uid, 'checks');
        const q = query(ref, orderBy('createdAt', 'desc'), limit(10));
        const snapshot = onSnapshot(q, handleFetch);
        
        return () => {
            snapshot();
            isSubscribed = false;
        };
    }, [enqueueSnackbar, db, uid]);

    useEffect(() => {
        const {filePath, thumbnailPath} = image || {};

        let isSubscribed = true;

        const fetch = async() => {
            try {
                if (thumbnailPath) {
                    const url = await getDownloadURL(ref(storage, thumbnailPath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }

                if (filePath) {
                    const url = await getDownloadURL(ref(storage, filePath));
                    if (isSubscribed) {
                        setImageUrl(url);
                    }

                    return;
                }
            } catch(e) {
                console.warn(e);
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [image]);

    const handleStartWeeklyCheck = async() => {
        let checkId;

        if (activeWeekly) {
            checkId = activeWeekly.uid;
        } else {
            let checks;

            try {
                const ref = collection(db, 'checks');
                const q = query(ref, where('apparatus', '==', uid), where('type', '==', 'weekly'), orderBy('createdAt', 'desc'), limit(1));
                const docs = await getCollection(db, q);

                const [record] = docs || [];
                if (!record) {
                    enqueueSnackbar('Checklist not found. Please contact the administrator.', {variant: 'error'});
                    return;
                }

                ({checks = []} = record);
        
                const data = {
                    type: 'weekly',
                    loggedChecks: checks,
                    createdAt: new Date(),
                    active: true,
                    users: !isStation && currentUserUid ? [currentUserUid] : []
                };
            
                const checkRef = collection(db, 'apparatus', uid, 'checks');
                const checkRecordRaw = await addDoc(checkRef, data);

                checkId = checkRecordRaw.id;
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
        }

        if (checkId) {
            navigate(`/apparatus/${uid}/weekly/${checkId}`);
        }
        
    };

    const handleStartPretrip = async() => {
        let checkId;

        if (activePretrip) {
            checkId = activePretrip.uid;
        } else {
            const data = {
                type: 'pretrips',
                createdAt: new Date(),
                active: true,
                users: !isStation && currentUserUid ? [currentUserUid] : []
            };
    
            try {
                const checkRef = collection(db, 'apparatus', uid, 'checks');
                const checkRecordRaw = await addDoc(checkRef, data);

                checkId = checkRecordRaw.id;
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }
        }

        if (checkId) {
            navigate(`/apparatus/${uid}/pretrips/${checkId}`);
        }
    };

    if (loading) {
        return (
            <Backdrop invisible open>
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    const latestWeekly = checks.find(check => check.type === 'weekly');
    const activeWeekly = activeChecks.find(check => check.type === 'weekly');
    const activePretrip = activeChecks.find(check => check.type === 'pretrips');

    const isOldWeeklyCheck = (latestWeekly && moment(latestWeekly.createdAt).isBefore(moment().subtract(12, 'days'))) || !latestWeekly;

    let borderColor = color;
    try {
        borderColor = darken(color, 0.2);
    } catch(e) {
        console.warn(e);
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Box sx={{display: 'flex', pb: 1, alignItems: 'center'}}>
                {color && (
                    <Tooltip title="Apparatus Color">
                        <Box
                            sx={{
                                mr: 1,
                                width: 30,
                                borderRadius: 1,
                                aspectRatio: 1,
                                background: color,
                                border: '1px solid',
                                borderColor
                            }}
                            variant="filled"
                        />
                    </Tooltip>
                )}

                <Typography variant="h5" sx={{flex: 1}}>{tag ? tag : <Skeleton width={180} />}</Typography>

                {hasPermission(currentUser, 'editChecks.write') && (
                    <>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<EditIcon />}
                            onClick={() => navigate(`/apparatus/${uid}/weekly/edit`)}
                            sx={{mr: 1}}
                        >
                            Edit Weekly Truck Check
                        </Button>
                        <Tooltip title="Edit Apparatus">
                            <IconButton disabled={loading} onClick={() => navigate(`/apparatus/${uid}/edit`)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
            </Box>

            <Divider sx={{mb: 2}} />

            <Grid container spacing={2}>
                <Grid size={{xs: 12, sm: 4}}>
                    <Card sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <CardMedia
                            sx={{aspectRatio: '16/9', width: '100%', borderRadius: 0.5, backgroundColor: 'background.paper'}}
                            image={imageUrl}
                            title={tag}
                        >
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', color: 'text.secondary', opacity: 0.5}}>
                                {!image && (
                                    <HideImageIcon sx={{color: 'grey.500', fontSize: 40}} />
                                )}
                            </Box>
                        </CardMedia>
                    </Card>
                </Grid>
                
                <Grid size={{xs: 12, sm: 8}}>
                    <Stack spacing={1}>
                        <Alert
                            severity={activeWeekly ? 'warning' : (isOldWeeklyCheck ? 'error' : 'success')}
                            action={activeWeekly && (
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="inherit"
                                    onClick={handleStartWeeklyCheck}
                                    startIcon={<ChecklistIcon />}
                                >
                                    {activeWeekly ? 'Continue' : 'Start'} Weekly Truck Check
                                </Button>
                            )}
                        >
                            {activeWeekly ? (
                                'There is an active weekly truck check'
                            ) : (
                                latestWeekly ? (
                                    <>The last weekly truck check was completed {moment(latestWeekly?.createdAt).fromNow()}</>
                                ) : (
                                    <>There is no recent weekly truck check</>
                                )
                            )}
                        </Alert>

                        {activePretrip && (
                            <Alert
                                severity={'warning'}
                                action={(
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="inherit"
                                        onClick={handleStartPretrip}
                                        startIcon={<FireTruckIcon />}
                                    >
                                        Continue Pretrip
                                    </Button>
                                )}
                            >
                                There is an active pretrip
                            </Alert>
                        )}

                        <Stack direction="row" sx={{justifyContent: {xs: 'center', sm: 'flex-end'}}} spacing={1}>
                            {!activeWeekly && (
                                <Button
                                    variant="contained"
                                    onClick={handleStartWeeklyCheck}
                                    startIcon={<ChecklistIcon />}
                                    sx={{flex: {xs: 1, sm: '0 1 auto'}}}
                                >
                                    {activeWeekly ? 'Continue' : 'Start'} Weekly Truck Check
                                </Button>
                            )}

                            {!activePretrip && (
                                <Button
                                    variant="outlined"
                                    onClick={handleStartPretrip}
                                    startIcon={<FireTruckIcon />}
                                    sx={{flex: {xs: 1, sm: '0 1 auto'}}}
                                >
                                    {activePretrip ? 'Continue' : 'Start'} Pretrip
                                </Button>
                            )}
                        </Stack>

                        {/* <Button sx={{mt: 2}} variant="outlined" disabled onClick={() => navigate(`/apparatus/${uid}/full`)}>Full Truck Check</Button> */}
                    </Stack>
                </Grid>
            </Grid>

            <History loading={loading} rows={checks} />
        </Box>
    );
}