import React, {createRef, useState, useEffect} from 'react';
import {Paper, Card, CardActionArea, IconButton} from '@mui/material';
import {useFormContext} from 'react-hook-form';
import {ref, getDownloadURL} from 'firebase/storage';
import {uniq, castArray} from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import {storage} from '-/firebase';

import HiddenFileField from './HiddenFileField';

export default function ImageCard(props) {
    const {name = 'image', sx, ...rest} = props;
    const {watch, setValue} = useFormContext();
    const data = castArray(watch(name, []));
    const [existingImages, setExistingImages] = useState([]);
    const [deleted, setDeleted] = useState([]);
    const files = watch(`${name}File`) || [];
    const imageUploadRef = createRef();

    useEffect(() => {
        let isSubscribed = true;

        const fetch = async() => {
            const fetchedUrls = await Promise.all(data.map(async file => {
                const {thumbnailPath, filePath, id} = file || {};

                try {
                    if (thumbnailPath) {
                        const url = await getDownloadURL(ref(storage, thumbnailPath));
                        return {id, url};
                    }
        
                    if (filePath) {
                        const url = await getDownloadURL(ref(storage, filePath));
                        return {id, url};
                    }
                } catch(e) {
                    console.warn(e);
                }

                return {id};
            }));
            
            if (isSubscribed) {
                setExistingImages(prevUrls => {
                    if (JSON.stringify(prevUrls) !== JSON.stringify(fetchedUrls)) {
                        return fetchedUrls;
                    }

                    return prevUrls;
                });
            }
        };

        fetch();

        return () => isSubscribed = false;
    }, [data]);

    const handleImageUpload = () => {
        imageUploadRef.current.click();
    };

    const handleDelete = id => {
        const newDeleted = uniq([...deleted, id]);

        setDeleted(newDeleted);

        setValue(`${name}Deleted`, data.filter(file => newDeleted.includes(file.id)));
    };

    const nonDeletedImages = existingImages.filter(({id}) => !deleted.includes(id));

    return (
        <Paper sx={{p: 1, display: 'flex', flexWrap: 'wrap', gap: 1, ...sx}} variant="outlined" {...rest}>
            {(nonDeletedImages || []).map((existingImage, index) => {
                const {id, url: backgroundImageUrl} = existingImage;

                return (
                    <Card
                        key={`upload-image-${index}`}
                        variant="outlined"
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            p: 1,
                            height: 140,
                            ...backgroundImageUrl && {backgroundImage: `url(${backgroundImageUrl})`},
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            aspectRatio: '4/3',
                            ...sx
                        }}
                    >
                        <IconButton onClick={() => handleDelete(id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Card>
                );
            })}

            {files.map((file, index) => {
                const backgroundImageUrl = file && URL.createObjectURL(file);

                return (
                    <Card
                        key={`upload-image-file-${index}`}
                        variant="outlined"
                        sx={{
                            display: 'flex',
                            height: 140,
                            ...backgroundImageUrl && {backgroundImage: `url(${backgroundImageUrl})`},
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            aspectRatio: '4/3',
                            ...sx
                        }}>
                        <CardActionArea
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flex: 1
                            }}
                            onClick={handleImageUpload}
                        />
                    </Card>
                );
            })}

            <Card
                variant="outlined"
                sx={{
                    display: 'flex',
                    height: 140,
                    aspectRatio: '4/3',
                    ...sx
                }}>
                <CardActionArea
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flex: 1
                    }}
                    onClick={handleImageUpload}
                >
                    <AddIcon />
                </CardActionArea>
                <HiddenFileField
                    ref={imageUploadRef}
                    multiple
                    accept="image/*"
                    name={`${name}File`}
                />
            </Card>
        </Paper>
    );
};