import React, {useState, useCallback} from 'react';
import {Button, DialogContent, DialogTitle, DialogActions} from '@mui/material';
import {writeBatch, doc, collection} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import {omit} from 'lodash';
import {LoadingButton} from '@mui/lab';
import moment from 'moment';
import {useGridApiRef} from '@mui/x-data-grid-pro';

import {db} from '-/firebase.js';

import {ensureJSDates} from '-/data/utils';

import IncidentsGrid from './Grid';

import ResponsiveDialog from '-/components/ResponsiveDialog';

const ImportButton = ({selectionCount, ...rest}) => {
    return (
        <LoadingButton variant="contained" {...rest}>
            Import Selected ({selectionCount})
        </LoadingButton>
    );
};

// const autosizeOptions = {
//     // columns: [
//     //     'date',
//     //     'incidentNumber',
//     //     'type',
//     //     'incidentTypeCode',
//     //     'dispatchType',
//     //     'users'
//     // ],
//     includeHeaders: true,
//     includeOutliers: true,
//     expand: true
// };

export default function Import(props) {
    const {incidents = [], onClose, open} = props;

    const apiRef = useGridApiRef();
    const [importing, setImporting] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    
    const handleImport = useCallback(async () => {
        const incidentsToImport = incidents.filter(incident => {
            const {uniqueId} = incident;
            return rowSelectionModel.includes(uniqueId);
        });

        if (!incidentsToImport.length) {
            enqueueSnackbar('No incidents selected', {variant: 'error'});
            return;
        }

        setImporting(true);

        try {
            const batch = writeBatch(db);

            for (const incident of incidentsToImport) {
                // const {uniqueId} = incident;
                const incidentRef = doc(collection(db, 'incidents'));

                const {apparatus, users, date: rawDate, ...rest} = omit(incident, ['foundExisting', 'issues']);

                batch.set(incidentRef, ensureJSDates({
                    ...rest,
                    date: moment(rawDate),
                    apparatus: apparatus.map(apparatus => apparatus.uid),
                    users: users.map(user => user.uid)
                }));
            }

            await batch.commit();
            
            enqueueSnackbar('Incidents imported', {variant: 'success'});

            onClose();
        } catch(e) {
            enqueueSnackbar(`Error importing incidents: ${e}`, {variant: 'error'});
        }

        setImporting(false);
    }, [enqueueSnackbar, incidents, rowSelectionModel, db]);

    return (
        <ResponsiveDialog maxWidth="xl" open={open} onClose={onClose}>
            <DialogTitle sx={{display: 'flex', alignItems: 'stretch', flexDirection: 'column'}}>
                Import Incidents
            </DialogTitle>
            <DialogContent dividers>
                <IncidentsGrid
                    stateId="incidents-import"
                    apiRef={apiRef}
                    editMode="row"
                    getRowId={row => row.uniqueId}
                    checkboxSelection
                    onRowSelectionModelChange={newRowSelectionModel => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    rows={incidents}
                    columns={[
                        {
                            field: 'foundExisting',
                            headerName: 'Duplicate',
                            width: 100,
                            renderCell: params => {
                                const {row} = params;
                                const {foundExisting = false} = row || {};
                                return foundExisting ? 'Yes' : 'No';
                            }
                        }
                    ]}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <ImportButton loading={importing} onClick={handleImport} selectionCount={rowSelectionModel.length} />
            </DialogActions>
        </ResponsiveDialog>
    );
}
