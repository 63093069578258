import React, {useContext} from 'react';
import {groupBy} from 'lodash';
import {FirefighterRanks, TrainingDisciplines} from '@embertracking/common';

import {UserContext} from '-/contexts/User';

import SearchableDataGrid from '-/components/SearchableDataGrid';

export default function({loading, training, onFilter}) {
    const trainingByDiscipline = groupBy(training, 'discipline');
    const {users: allUsers} = useContext(UserContext);
    const users = allUsers.filter(user => FirefighterRanks.includes(user.role)).filter(doc => doc.deactivated !== true);

    const data = Object.keys(TrainingDisciplines).map(discipline => {
        const total = trainingByDiscipline[discipline]?.length || 0;

        const hours = trainingByDiscipline[discipline]?.reduce((total, training) => {
            const {hours} = training;
            return total + parseFloat(hours);
        }, 0) || 0;

        const totalHours = trainingByDiscipline[discipline]?.reduce((total, training) => {
            const {hours, members = []} = training;
            return total + (parseFloat(hours) * members.length);
        }, 0) || 0;

        const practiceTraining = (trainingByDiscipline[discipline] || []).filter(training => training.type === 'PRACTICE');
        const practicePercentage = (practiceTraining.length / total) * 100;
        const practiceAttendancePercentage = (practiceTraining.reduce((total, training) => {
            const {members = []} = training;
            return total + members.length;
        }, 0) / (users.length * practiceTraining.length)) * 100;

        return {
            id: discipline,
            label: TrainingDisciplines[discipline],
            total,
            hours,
            totalHours,
            practicePercentage,
            practiceAttendancePercentage
        };
    });

    const handleCellClick = () => { //{field, row}
        if (!onFilter) {
            return;
        }

        
    };

    return (
        <SearchableDataGrid
            initialState={{
                density: 'compact'
            }}
            loading={loading}
            autoHeight
            rows={data}
            showTotals
            hideFooter
            disableColumnSelector
            sx={{mt: 1, mb: 2}}
            onCellClick={handleCellClick}
            columns={[
                {
                    field: 'label',
                    headerName: 'Discipline',
                    minWidth: 120,
                    flex: 1
                },
                {
                    field: 'total',
                    headerName: 'Sessions',
                    type: 'number',
                    total: 'sum',
                    description: 'Total number of training sessions'
                },
                {
                    field: 'hours',
                    headerName: 'Hours',
                    type: 'number',
                    total: 'sum',
                    description: 'Total number of training hours'
                },
                {
                    field: 'totalHours',
                    headerName: 'Member Hours',
                    type: 'number',
                    total: 'sum',
                    minWidth: 150,
                    description: 'Total number of training hours for all attending members'
                },
                {
                    field: 'practicePercentage',
                    headerName: 'Scheduled Practice %',
                    minWidth: 200,
                    total: 'average',
                    headerAlign: 'center',
                    align: 'center',
                    valueFormatter: value => `${(value || 0).toFixed(0)}%`,
                    description: 'Average percentage of training sessions that are practice'
                },
                {
                    field: 'practiceAttendancePercentage',
                    headerName: 'Avg. Practice Attendance %',
                    total: 'average',
                    headerAlign: 'center',
                    align: 'center',
                    minWidth: 200,
                    valueFormatter: value => `${(value || 0).toFixed(0)}%`,
                    description: 'Average percentage of members that attended practice sessions'
                }
            ]}
        />
    );
};