import React, {useState, useEffect, useCallback} from 'react';
import {DataGrid, GridActionsCellItem, GridToolbarContainer, GridRowModes, GridRowEditStopReasons} from '@mui/x-data-grid';
import {Typography, Button} from '@mui/material';
import {useSnackbar} from 'notistack';
import {get} from 'lodash';
import {collection, doc, addDoc, deleteDoc, updateDoc} from 'firebase/firestore';
import {
    Add as AddIcon,
    Cancel as CancelIcon,
    Save as SaveIcon,
    Edit as EditIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';
import {v4 as uuid} from 'uuid';

import {db} from '-/firebase.js';

import {getCollection, ensureJSDates, ensureMomentDates} from '-/data/utils';

const EditToolbar = props => {
    const {docs, setDocs, setRowModesModel, columns, label} = props;

    const handleClick = () => {
        if (docs.some(row => row.isNew)) {
            return;
        }

        const id = uuid();

        setDocs(oldRows => [
            ...oldRows,
            ensureMomentDates({
                id,
                isNew: true,
                createdAt: new Date(),
                updatedAt: new Date()
            })
        ]);

        const [firstColumn] = columns || [];
        const fieldToFocus = get(firstColumn, 'field', 'name');

        setRowModesModel(oldModel => ({
            ...oldModel,
            [id]: {mode: GridRowModes.Edit, fieldToFocus}
        }));
    };

    return (
        <GridToolbarContainer sx={{flexDirection: 'column', alignItems: 'flex-end', pb: 0.5, bgcolor: 'background.paper', borderBottom: '1px solid', borderColor: 'divider'}}>
            <Button color="primary" variant="contained" size="small" startIcon={<AddIcon />} onClick={handleClick}>
                Add {label}
            </Button>
        </GridToolbarContainer>
    );
};

export default function(props) {
    const {label = 'Item', collection: collectionName, columns = [], ...rest} = props;

    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState([]);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [rowModesModel, setRowModesModel] = useState({});

    useEffect(() => {
        if (!collection) {
            return;
        }

        let isSubscribed = true;

        async function fetch() {
            try {
                const docs = await getCollection(db, collectionName);

                if (isSubscribed) {
                    setDocs(docs);
                }
            } catch(e) {
                enqueueSnackbar(e.message, {variant: 'error'});
            }

            if (isSubscribed) {
                setLoading(false);
            }
        }

        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, collection]);

    const handleRowModesModelChange = newRowModesModel => {
        setRowModesModel(newRowModesModel);
    };

    const handleProcessRowUpdate = useCallback(async updatedRow => {
        const {isNew, ...rest} = updatedRow;
        const now = new Date();

        try {
            if (isNew) {
                let data = ensureJSDates({
                    ...rest,
                    createdAt: now,
                    updatedAt: now
                });

                const ref = collection(db, collectionName);
                const {id} = await addDoc(ref, data);

                enqueueSnackbar(`${label} added`, {variant: 'success'});

                data = ensureMomentDates({...data, id});

                setDocs(existingDocs => ([
                    ...existingDocs.filter(row => !row.isNew),
                    data
                ]));

                return data;
            } else {
                const {id} = updatedRow;
                let data = ensureJSDates({
                    ...rest,
                    updatedAt: new Date()
                });

                const ref = doc(db, collectionName, id);
                await updateDoc(ref, data);

                enqueueSnackbar(`${label} updated`, {variant: 'success'});

                data = ensureMomentDates(data);
                return data;
            }
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [db, enqueueSnackbar, docs]);

    const handleProcessRowUpdateError = useCallback(error => {
        enqueueSnackbar(error.message, {variant: 'error'});
    }, []);

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = id => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = id => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = id => async() => {
        const onDelete = async() => {
            const ref = doc(db, collectionName, id);
            await deleteDoc(ref);
                
            setDocs(docs.filter(row => row.id !== id));
        };

        enqueueSnackbar(`Are you sure you want to delete this ${label}?`, {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            onDelete();
                        }}>
                            Delete
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    };

    const handleCancelClick = id => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true}
        });

        const editedRow = docs.find(row => row.id === id);
        if (editedRow.isNew) {
            setDocs(docs.filter(row => row.id !== id));
        }
    };

    const defaultColumns = [
        {
            type: 'actions',
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = get(rowModesModel[id], 'mode') === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            key={`save-${id}`}
                            sx={{
                                color: 'primary.main'
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            key={`cancel-${id}`}
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        key={`edit-${id}`}
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        key={`delete-${id}`}
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />
                ];
            }
        }
    ];

    return (
        <>
            <Typography variant="h5" gutterBottom>{label}s</Typography>

            <div style={{display: 'flex', flexDirection: 'column', minHeight: 150}}>
                <DataGrid
                    getRowHeight={() => 'auto'}
                    loading={loading}
                    rows={docs}
                    columns={[
                        ...columns,
                        ...defaultColumns
                    ]}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={handleProcessRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    hideFooter
                    slots={{
                        toolbar: EditToolbar
                    }}
                    slotProps={{
                        toolbar: {docs, setDocs, setRowModesModel, label, columns}
                    }}
                    sx={{
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {py: '8px'},
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: 1},
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {py: '22px'}
                    }}
                    {...rest}
                />
            </div>
        </>
    );
};