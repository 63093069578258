import React, {useState, useContext} from 'react';
import {styled} from '@mui/material/styles';
import {Box, Stack, Tabs, Tab, Typography, Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import {UserContext} from '-/contexts/User';

import {hasPermission} from '-/data/utils';

import Type from './stats/Type';
import Member from './stats/Member';
import Maps from './stats/Maps';

const Accordion = styled(props => (
    <MuiAccordion sx={{boxShadow: 0}} disableGutters elevation={0} square {...props} />
))(({theme}) => `
    padding: 0;
    border: 1px solid ${theme.palette.divider};
    &:not(:last-child) {
        border-bottom: 0;
    }
    &:before {
        display: none;
    }
`);

const AccordionSummary = styled(props => (
    <MuiAccordionSummary {...props} />
))(({theme}) => `
    background-color: rgba(0, 0, 0, .03);
    flex-direction: row-reverse;
    & .MuiAccordionSummary-expandIconWrapper {
        transform: rotate(90deg);
    }
    & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        transform: rotate(180deg);
    }
    & .MuiAccordionSummary-content {
        margin-left: ${theme.spacing(1)};
    }
`);

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => `
    padding: ${theme.spacing(2)};
    border-top: 1px solid rgba(0, 0, 0, .125);
`);

const TabPanel = props => {
    const {children, value, index, ...rest} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`stats-tabpanel-${index}`}
            aria-labelledby={`stats-tab-${index}`}
            {...rest}
        >
            {value === index && (
                <Box sx={{width: '100%', pt: 2}}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const Stats = props => {
    const {incidents, scrollToIncident, expanded = false} = props;
    const [value, setValue] = useState(0);
    const {currentUser} = useContext(UserContext);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabs = [
        {
            title: 'By Type',
            component: <Type incidents={incidents} scrollToIncident={scrollToIncident} />
        },
        {
            title: 'By Member',
            component: <Member incidents={incidents} scrollToIncident={scrollToIncident} />
        }
    ];

    if (hasPermission(currentUser, 'incidents.read')) {
        tabs.push({
            title: 'Maps',
            component: <Maps incidents={incidents} scrollToIncident={scrollToIncident} />
        });
    }

    return (
        <Box sx={{pb: 1}}>
            <Accordion elevation={1} disableGutters defaultExpanded={expanded}>
                <AccordionSummary expandIcon={<ExpandLessIcon />} id="panel1a-header">
                    <Stack direction="row" sx={{flex: 1, justifyContent: 'space-between'}}>
                        <Typography sx={{fontWeight: 'bold'}}>Statistics</Typography>
                        <Typography sx={{color: 'text.secondary'}}>{incidents && `${incidents.length} Incidents`}</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange}>
                            {tabs.map((tab, index) => {
                                const {title} = tab || {};

                                return (
                                    <Tab key={`stats-tab-${index}`} label={title} />
                                );
                            })}
                        </Tabs>
                    </Box>

                    {tabs.map((tab, index) => {
                        const {component} = tab || {};

                        return (
                            <TabPanel key={`stats-tab-${index}`} value={value} index={index}>
                                {component}
                            </TabPanel>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default Stats;