import React from 'react';
import {useController} from 'react-hook-form';
import {capitalize} from 'lodash';
import {MuiTelInput} from 'mui-tel-input';

export default function ControlledPhoneField(props) {
    const {name, label, required, rule: rawRules, ...rest} = props;
    const rules = {...rawRules};
    if (required && !rules.required) {
        rules.required = `${label || capitalize(name)} is required`;
    }

    const {field, fieldState: {error}} = useController({name, rules});
    const {value: rawValue, ...restField} = field;

    let value = rawValue || '';
    if (value && !value.startsWith('+')) {
        value = `+${value}`;
    }

    return (
        <MuiTelInput
            value={value}
            {...restField}
            defaultCountry='CA'
            onlyCountries={['CA', 'US']}
            error={!!error}
            helperText={error ? error.message : null}
            {...rest}
        />
    );
};
