import React from 'react';
import {useController} from 'react-hook-form';
import {Autocomplete, TextField} from '@mui/material';
import {capitalize} from 'lodash';

export default function ControlledAutocompleteField(props) {
    const {name, label, required, rules: rawRules, helperText, options, multiple = true, ...rest} = props;
    const rules = {...rawRules};
    const fieldLabel = label !== false ? label || capitalize(name) : null;

    if (required && !rules.required) {
        rules.required = fieldLabel ? `${fieldLabel} is required` : 'This field is required';
    }

    const {field, fieldState: {error}} = useController({name, rules});
    const {value, onChange, ...restField} = field;

    return (
        <Autocomplete
            value={value || (multiple ? [] : '')}
            options={options}
            onChange={(e, newValue) => {
                onChange(newValue);
            }}
            getOptionLabel={option => option.label || ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            filterSelectedOptions
            renderInput={params => (
                <TextField
                    name={name}
                    {...params}
                    required={required}
                    label={label}
                    error={!!error}
                    helperText={error && helperText !== false ? error.message : helperText}
                />
            )}
            multiple={multiple}
            {...rest}
            {...restField}
        />
    );
};
