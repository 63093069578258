import React, {useState, useCallback, useEffect} from 'react';
import {CircularProgress, Box, List, ListItemButton, ListItemAvatar, ListItemText, Divider, Avatar} from '@mui/material';
import axios from 'axios';
import {LoadingButton} from '@mui/lab';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import CachedIcon from '@mui/icons-material/Cached';

import {db, auth} from '-/firebase';

import {getCollection} from '-/data/utils';

export default function App() {
    const [loading, setLoading] = useState(true);
    const [fetching, setFetching] = useState(true);
    const [responses, setResponses] = useState([]);
    const navigate = useNavigate();

    const isDev = process.env.NODE_ENV === 'development';
    const base = isDev ? 'http://127.0.0.1:5001/jrfd-log/us-central1/' : 'https://us-central1-jrfd-log.cloudfunctions.net/';

    const fetch = useCallback(async() => {
        setLoading(true);

        const result = await getCollection(db, 'responses');

        setResponses(result);

        setLoading(false);
    }, [db]);

    const fetchLatest = useCallback(async() => {
        setFetching(true);

        const token = await auth.currentUser.getIdToken();
        await axios({
            url: `${base}getLatestResponses`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        fetch();

        setFetching(false);
    }, [base, fetch]);

    useEffect(() => {
        fetch();
        fetchLatest();
    }, [fetch, fetchLatest]);

    if (loading) {
        return (
            <Box sx={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', mb: 1}}>
                <LoadingButton startIcon={<CachedIcon />} loading={fetching} variant="contained" sx={{ml: 1}} onClick={() => fetchLatest()}>
                    Refresh
                </LoadingButton>
            </Box>

            <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                {responses.map((item, index) => {
                    const {uid, date, type, location} = item;
                    const isToday = moment(date).isSame(new Date(), 'day');
                    
                    return (
                        <Box key={`response-${index}`}>
                            <ListItemButton selected={isToday} alignItems="flex-start" onClick={() => navigate(`/respond/${uid}`)}>
                                <ListItemAvatar>
                                    <Avatar sx={{width: 100, borderRadius: 2, mr: 2}} alt={type}>{type.substring(0, 6)}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={location}
                                    secondary={isToday ? moment(date).fromNow() : moment(date).format('LLLL')}
                                />
                            </ListItemButton>
                            <Divider component="li" />
                        </Box>
                    );
                })}
            </List>
        </>
    );
}
