import {alpha} from '@mui/material/styles';
import {pickersYearClasses, pickersDayClasses} from '@mui/x-date-pickers';
import {menuItemClasses} from '@mui/material/MenuItem';
import {gray, brand} from '-/style/themePrimitives';

export const datePickersCustomizations = {
    MuiPickersPopper: {
        styleOverrides: {
            paper: ({theme}) => ({
                marginTop: 4,
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${(theme.vars || theme).palette.divider}`,
                backgroundImage: 'none',
                background: 'hsl(0, 0%, 100%)',
                boxShadow:
          'hsla(220, 30%, 5%, 0.07) 0px 4px 16px 0px, hsla(220, 25%, 10%, 0.07) 0px 8px 16px -5px',
                [`& .${menuItemClasses.root}`]: {
                    borderRadius: 6,
                    margin: '0 6px'
                }
            })
        }
    },
    MuiPickersArrowSwitcher: {
        styleOverrides: {
            spacer: {width: 16},
            button: ({theme}) => ({
                backgroundColor: 'transparent',
                color: (theme.vars || theme).palette.grey[500]
            })
        }
    },
    MuiPickersCalendarHeader: {
        styleOverrides: {
            switchViewButton: {
                padding: 0,
                border: 'none'
            }
        }
    },
    // MuiPickersMonth: {
    //   styleOverrides: {
    //     monthButton: ({ theme }) => ({
    //       fontSize: theme.typography.body1.fontSize,
    //       color: (theme.vars || theme).palette.grey[600],
    //       padding: theme.spacing(0.5),
    //       borderRadius: theme.shape.borderRadius,
    //       '&:hover': {
    //         backgroundColor: (theme.vars || theme).palette.action.hover,
    //       },
    //       [`&.${pickersMonthClasses.selected}`]: {
    //         backgroundColor: gray[700],
    //         fontWeight: theme.typography.fontWeightMedium,
    //       },
    //       '&:focus': {
    //         outline: `3px solid ${alpha(brand[500], 0.5)}`,
    //         outlineOffset: '2px',
    //         backgroundColor: 'transparent',
    //         [`&.${pickersMonthClasses.selected}`]: { backgroundColor: gray[700] },
    //       }
    //     }),
    //   },
    // },
    MuiPickersYear: {
        styleOverrides: {
            yearButton: ({theme}) => ({
                fontSize: theme.typography.body1.fontSize,
                color: (theme.vars || theme).palette.grey[600],
                padding: theme.spacing(0.5),
                // borderRadius: theme.shape.borderRadius,
                height: 'fit-content',
                '&:hover': {
                    backgroundColor: (theme.vars || theme).palette.action.hover
                },
                [`&.${pickersYearClasses.selected}`]: {
                    backgroundColor: gray[700],
                    fontWeight: theme.typography.fontWeightMedium
                },
                '&:focus': {
                    outline: `3px solid ${alpha(brand[500], 0.5)}`,
                    outlineOffset: '2px',
                    backgroundColor: 'transparent',
                    [`&.${pickersYearClasses.selected}`]: {backgroundColor: gray[700]}
                }
            })
        }
    },
    MuiPickersDay: {
        styleOverrides: {
            root: ({theme}) => ({
                fontSize: theme.typography.body1.fontSize,
                color: (theme.vars || theme).palette.grey[600],
                padding: theme.spacing(0.5),
                // borderRadius: theme.shape.borderRadius,
                '&:hover': {
                    backgroundColor: (theme.vars || theme).palette.action.hover
                },
                [`&.${pickersDayClasses.selected}`]: {
                    backgroundColor: gray[700],
                    fontWeight: theme.typography.fontWeightMedium
                },
                '&:focus': {
                    outline: `3px solid ${alpha(brand[500], 0.5)}`,
                    outlineOffset: '2px',
                    backgroundColor: 'transparent',
                    [`&.${pickersDayClasses.selected}`]: {backgroundColor: gray[700]}
                }
            })
        }
    }
};
