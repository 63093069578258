import React, {useEffect, useMemo, useState, useCallback, useContext} from 'react';
import {Button, Box, Typography, Divider, AvatarGroup} from '@mui/material';
import moment from 'moment';
import {get, castArray} from 'lodash';
import {useParams} from 'react-router-dom';
import {doc, collection, updateDoc, deleteDoc, onSnapshot, addDoc} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import {RecordTypes} from '@embertracking/common';

import {db} from '-/firebase';
import {UserContext} from '-/contexts/User';

import {processRawDocs, populateDocsWithUsers, ensureJSDates, hasPermission} from '-/data/utils';

import RecordDialog from './RecordDialog';
import SearchableDataGrid from '-/components/SearchableDataGrid';
import UserAvatar from '-/components/UserAvatar';

export default function RecordsGrid(props) {
    const [open, setOpen] = useState(false);
    const [record, setRecord] = useState(null);
    const {currentUser, users} = useContext(UserContext);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';
    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const refArgs = useMemo(() => {
        const {id: uid, ...otherParams} = params;
        let refArgs = [db];

        Object.entries(otherParams).forEach(([key, param]) => {
            if (key !== '*' && param) {
                refArgs.push('gear', param);
            }
        });

        refArgs.push('items', uid);
        refArgs.push('records');

        return refArgs;
    }, [db, params]);

    useEffect(() => {
        setLoading(true);

        const ref = collection(...refArgs);
        const unsubscribe = onSnapshot(ref, async snapshot => {
            const records = populateDocsWithUsers(processRawDocs(snapshot), users);

            setRecords(records);

            setLoading(false);
        });
        
        return () => {
            unsubscribe();
        };
    }, [db, refArgs, users]);

    const onAdd = useCallback(async data => {
        try {
            const ref = collection(...refArgs);
            const {users = [], ...rest} = data;

            const record = {
                ...rest,
                createdAt: new Date(),
                users: users.map(user => user.uid || user)
            };
            
            await addDoc(ref, record);
            return record;
        } catch (e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    }, [enqueueSnackbar, refArgs]);

    const onUpdate = async data => {
        const {uid, users = [], ...rest} = data;
        const toUpdate = ensureJSDates(rest);

        const ref = doc(...refArgs, uid);

        if (Object.keys(toUpdate)) {
            await updateDoc(ref, {
                ...toUpdate,
                updatedAt: new Date(),
                users: users.map(user => user.uid || user)
            });
        }

        return true;
    };
    
    const onDelete = data => {
        const {uid} = data;

        return new Promise(resolve => {
            const onDelete = async() => {
                const ref = doc(...refArgs, uid);
                await deleteDoc(ref);

                resolve(true);
            };

            enqueueSnackbar('Are you sure you want to delete this record?', {
                variant: 'warning',
                action: key => {
                    return (
                        <>
                            <Button onClick={() => {
                                closeSnackbar(key);
                                onDelete();
                            }}>
                                Delete
                            </Button>
                            <Button onClick={() => {
                                resolve(true);
                                closeSnackbar(key);
                            }}>
                                Cancel
                            </Button>
                        </>
                    );
                }
            });
        });
    };

    const onRowClick = useCallback(params => {
        if (!hasPermission(currentUser, 'gear.write')) {
            return;
        }

        const {row} = params;

        setRecord(row);
        setOpen(true);
    }, []);

    const handleClose = () => {
        setOpen(false);
        setRecord(null);
    };

    const columns = [
        {
            field: 'createdAt',
            headerName: 'Created',
            width: 105,
            sortable: true,
            valueFormatter: value => {
                return value ? moment(value).format(dateFormat) : '-';
            }
        },
        {
            field: 'type',
            headerName: 'Type',
            minWidth: 150,
            sortable: true,
            valueFormatter: value => {
                if (!value) {
                    return '-';
                }
                
                return RecordTypes[value] || '-';
            }
        },
        {
            field: 'users',
            headerName: 'Members',
            minWidth: 150,
            sortable: false,
            renderCell: params => {
                const {value} = params || {};
                const users = castArray(value);

                const avatars = users.map((member, index) => {
                    const {id} = member || {};

                    return (
                        <UserAvatar key={`${id}-${index}`} user={member} />
                    );
                });

                return (
                    <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                        <AvatarGroup>
                            {avatars}
                        </AvatarGroup>
                    </Box>
                );
            }
        },
        {
            field: 'note',
            headerName: 'Notes',
            flex: 1
        }
    ];

    const stateId = refArgs.slice(1).join('-');

    return (
        <Box {...props}>
            <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                <Typography variant="h6">Records</Typography>

                <RecordDialog onSubmit={record ? hasPermission(currentUser, 'gear.write') && onUpdate : onAdd} onDelete={hasPermission(currentUser, 'gear.write') && onDelete} record={record} open={open} handleClose={handleClose} />
                <Box sx={{flexGrow: 1}} />
                <Button disabled={loading} size="small" startIcon={<AddIcon />} variant="outlined" onClick={() => setOpen(true)}>Add New Record</Button>
            </Box>

            <Divider sx={{mb: 2}} />

            {records.length === 0 ? (
                <Typography variant="body2">No records</Typography>
            ) : (
                <Box sx={{display: 'flex', height: '100%'}}>
                    <Box sx={{flexGrow: 1}}>
                        <SearchableDataGrid
                            stateId={stateId}
                            initialState={{
                                sorting: {
                                    sortModel: [
                                        {field: 'createdAt', sort: 'desc'}
                                    ]
                                }
                            }}
                            loading={loading}
                            autoHeight
                            rows={records}
                            columns={columns.map(column => ({...column, disableColumnMenu: true}))}
                            pageSizeOptions={[]}
                            disableRowSelectionOnClick
                            onRowClick={onRowClick}
                            hideFooter
                            slotProps={{
                                loadingOverlay: {
                                    variant: 'linear-progress',
                                    noRowsVariant: 'skeleton'
                                }
                            }}
                            sx={{
                                '& .MuiDataGrid-cell:focus-within': {
                                    outline: 'none'
                                }
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};