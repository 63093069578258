import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useNavigate, Link as RouterLink} from 'react-router-dom';
import {Button, Box, Typography, FormControlLabel, Checkbox, useMediaQuery} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useTheme} from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import moment from 'moment';
import parsePhoneNumber from 'libphonenumber-js';
import {get} from 'lodash';
import {Ranks} from '@embertracking/common';

import {db} from '-/firebase';
import {hasFeature} from '-/features';
import {UserContext} from '-/contexts/User';

import {hasPermission, getSnapshot, ensureMomentDates, populateKeyFromCollection} from '-/data/utils';

import UserAvatar from '-/components/UserAvatar';
import SearchableDataGrid from '-/components/SearchableDataGrid';

const renderImageCell = params => {
    const {row} = params;

    return (
        <Box sx={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <UserAvatar user={row} />
        </Box>
    );
};

const Members = () => {
    const [loading, setLoading] = useState(true);
    const [includeDeactivated, setIncludeDeactivated] = useState(false);
    const [docs, setDocs] = useState([]);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';

    useEffect(() => {
        const unsubscribe = getSnapshot(db, 'users', async docs => {
            let filtered = docs;
            filtered = await populateKeyFromCollection(db, docs, 'station', 'stations');

            if (!includeDeactivated) {
                filtered = filtered.filter(doc => doc.deactivated !== true);
            }

            setDocs(filtered.map(ensureMomentDates));
            setLoading(false);
        });
   
        return unsubscribe;
    }, [enqueueSnackbar, db, includeDeactivated]);

    const columns = [
        {
            field: 'Image',
            headerName: '',
            align: 'center',
            disableExport: true,
            width: isSmall ? 40 : 60,
            valueGetter: (value, row) => {
                return row && row.imageUrl ? row.imageUrl : '';
            },
            renderCell: renderImageCell,
            sortable: false
        },
        {
            field: 'fullName',
            headerName: 'Name',
            flex: 2,
            renderCell: params => {
                const {row} = params || {};
                const {fullName, email} = row || {};
                return fullName || email;
            }
        },
        {field: 'email', headerName: 'Email', flex: 2, sortable: false},
        {
            field: 'station',
            headerName: 'Station',
            minWidth: 100,
            valueGetter: value => {
                const {name} = value || {};
                return name || '-';
            }
        },
        {
            field: 'role',
            headerName: 'Rank',
            minWidth: 190,
            valueFormatter: value => {
                return Ranks[value];
            }
        }
    ];

    columns.push({
        field: 'dob',
        headerName: 'DOB',
        minWidth: 100,
        valueFormatter: value => {
            if (!value) {
                return '';
            }

            return value.format(dateFormat);
        }
    });

    if (hasPermission(currentUser, 'users.write')) {
        columns.push({
            field: 'employeeId',
            headerName: 'Employee ID',
            minWidth: 100,
            valueFormatter: value => {
                return (value || '').toUpperCase();
            }
        });
        
        columns.push({
            field: 'transcriptDate',
            headerName: 'Transcript Updated',
            minWidth: 120,
            valueFormatter: value => {
                return value ? value.format(dateFormat) : '-';
            }
        });

        if (hasFeature('hasJIBCConsent')) {
            columns.push({
                field: 'jibcConsentDate',
                headerName: 'JIBC Consent',
                minWidth: 140,
                sortable: true,
                align: 'center',
                renderCell: params => {
                    const {value} = params || {};
                    if (!value) {
                        return undefined;
                    }

                    return <CheckIcon />;
                }
            });
        }
    }

    columns.push({
        field: 'jibcNumber',
        headerName: 'JIBC #',
        minWidth: 100,
        valueFormatter: value => {
            return (value || '').toUpperCase();
        }
    });

    columns.push({
        field: 'phone',
        headerName: 'Phone #',
        minWidth: 130,
        valueFormatter: value => {
            const parsed = parsePhoneNumber(`${(value || '')}`, 'CA');

            if (parsed) {
                return parsed.formatNational();
            }

            return '';
        }
    });

    columns.push({
        field: 'age',
        headerName: 'Age',
        minWidth: 100,
        valueGetter: (value, row) => {
            const {dob} = row || {};
            if (!dob) {
                return '-';
            }

            return moment().diff(dob, 'years');
        }
    });

    columns.push({
        field: 'daysUntilDob',
        headerName: 'Days until DOB',
        minWidth: 140,
        valueGetter: (value, row) => {
            let {dob} = row || {};
            if (!dob) {
                return '-';
            }

            const year = moment().year();
            dob = moment(dob);
            dob.set('year', year);

            let diff = moment(dob).diff(moment(), 'days');
            if (diff < 0) {
                dob.set('year', year + 1);
                diff = moment(dob).diff(moment(), 'days');
            }

            return diff;
        }
    });

    if (hasPermission(currentUser)) {
        columns.push({
            field: 'lastSignIn',
            headerName: 'Last Sign In',
            minWidth: 140,
            valueFormatter: value => {
                return value ? value.fromNow() : '-';
            }
        });

        columns.push({
            field: 'emailVerified',
            headerName: 'Email Verified',
            minWidth: 120,
            renderCell: params => {
                const {value} = params || {};
                if (!value) {
                    return '';
                }

                return (
                    <Box sx={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <CheckIcon />
                    </Box>
                );
            }
        });
    }

    // columns.push({
    //     field: 'registered',
    //     headerName: 'Registered',
    //     minWidth: 100,
    //     sortable: true,
    //     align: 'center',
    //     renderCell: params => {
    //         const {value} = params || {};
    //         if (!value) {
    //             return '';
    //         }

    //         return (
    //             <Box sx={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
    //                 <CheckIcon />
    //             </Box>
    //         );
    //     }
    // });

    // if (hasFeature('authIssues')) {
    //     columns.push({
    //         field: 'hasAuthIssues',
    //         headerName: 'Auth Issues',
    //         width: 100,
    //         sortable: true,
    //         align: 'center',
    //         valueGetter: (value, row) => {
    //             return row && row.uid !== row.id;
    //         },
    //         renderCell: params => {
    //             const {value} = params || {};
    //             if (!value) {
    //                 return '';
    //             }

    //             return <CheckIcon />;
    //         }
    //     });
    // }

    const onRowClick = useCallback((params, e) => {
        const {metaKey} = e;
        const {row} = params;

        const url = `/users/${row.id}`;
        if (metaKey) {
            window.open(url);
            return;
        }

        navigate(url);
    }, [navigate]);

    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                <Typography variant="h5" sx={{flex: 1}}>Users</Typography>

                {hasPermission(currentUser, 'users.write') && (
                    <FormControlLabel
                        control={(
                            <Checkbox
                                onChange={() => setIncludeDeactivated(!includeDeactivated)}
                                checked={includeDeactivated}
                            />
                        )}
                        label="Include Deactivated"
                        sx={{ml: 1}}
                    />
                )}
            </Box>

            <SearchableDataGrid
                stateId="users"
                initialState={{
                    sorting: {
                        sortModel: [
                            {field: 'station', sort: 'asc'},
                            {field: 'lastName', sort: 'asc'}
                        ]
                    },
                    columns: {
                        columnVisibilityModel: {
                            email: false,
                            dob: false,
                            jibcNumber: false,
                            transcriptDate: false,
                            jibcConsentDate: false,
                            employeeId: false,
                            age: false,
                            daysUntilDob: false,
                            registered: false,
                            emailVerified: false,
                            lastSignIn: false
                        }
                    }
                }}
                loading={loading}
                autoHeight
                rows={docs}
                columns={columns.map(column => ({...column, disableColumnMenu: true}))}
                pageSizeOptions={[]}
                disableRowSelectionOnClick
                onRowClick={onRowClick}
                slotProps={{
                    loadingOverlay: {
                        variant: 'linear-progress',
                        noRowsVariant: 'skeleton'
                    }
                }}
                getRowClassName={params => {
                    const {row} = params;
                    const {deactivated = false} = row || {};
                    const classNames = [];

                    if (deactivated) {
                        classNames.push('deactivated');
                    }

                    return classNames.join(' ');
                }}
                sx={{
                    '& .deactivated': {
                        opacity: 0.6
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                        outline: 'none'
                    }
                }}
            />

            {hasPermission(currentUser, 'users.write') && (
                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                    <Button component={RouterLink} variant="contained" to="/users/add">Add User</Button>
                </Box>
            )}
        </>
    );
};

export default Members;