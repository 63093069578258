import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Box, Grid, Typography, CircularProgress, Card, CardContent, Button, Paper} from '@mui/material';
import {DataGridPro, GridActionsCellItem} from '@mui/x-data-grid-pro';
import {useParams} from 'react-router-dom';
import {useForm, FormProvider, useFieldArray, useFormContext} from 'react-hook-form';
import {useSnackbar} from 'notistack';
import {LoadingButton, Timeline, TimelineItem, TimelineDot, TimelineSeparator, TimelineConnector, TimelineOppositeContent} from '@mui/lab';
import moment from 'moment';
import {collection, getDocs, query, where, orderBy, addDoc, limit} from 'firebase/firestore';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

import {UserContext} from '-/contexts/User';
import {db} from '-/firebase';

import {populateUsers} from '-/data/utils';

import UserAvatar from '-/components/UserAvatar';
import TextField from '-/form/TextField.js';

const SectionChecks = props => {
    const {saving, loading, editing, sectionIndex} = props;
    const {control, setValue} = useFormContext();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const {fields, append, remove, swap} = useFieldArray({
        control,
        name: `sections[${sectionIndex}].checks`
    });

    const handleRowUpdate = useCallback(async data => {
        const {index, ...rest} = data;

        setValue(`sections[${sectionIndex}].checks[${index}]`, rest);

        return data;
    }, [setValue, sectionIndex]);
    
    const handleRowAdd = useCallback(() => {
        append({
            text: ''
        });
    }, [append]);

    const handleRowRemove = useCallback(index => {
        enqueueSnackbar('Are you sure you want to remove this item?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            remove(index);
                        }}>
                            Remove
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    }, [remove, enqueueSnackbar, closeSnackbar]);

    const handleRowOrderChange = useCallback(change => {
        const {oldIndex, targetIndex} = change;
        swap(oldIndex, targetIndex);
    }, [swap]);

    const columns = [
        {
            field: 'text',
            headerName: 'Item to Check',
            flex: 3,
            sortable: false,
            editable: editing
        },
        {
            field: 'qty',
            headerName: 'QTY',
            flex: 1,
            sortable: false,
            editable: editing
        }
    ];

    if (editing) {
        columns.push({
            headerName: '',
            type: 'actions',
            field: 'actions',
            editable: false,
            getActions: params => {
                const {row} = params;
                const {index} = row;

                return [
                    <GridActionsCellItem
                        key={`edit-full-check-${index}-delete`}
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => handleRowRemove(index)}
                    />
                ];
            }
        });
    }
    
    return (
        <Box sx={{flex: 1}}>
            <DataGridPro
                rowReordering={editing}
                onRowOrderChange={handleRowOrderChange}
                loading={loading}
                autoHeight
                hideFooter
                rows={fields.map((field, index) => ({...field, index}))}
                columns={columns}
                editMode="row"
                showCellVerticalBorder
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                processRowUpdate={handleRowUpdate}
                experimentalFeatures={{newEditingApi: true}}
                sx={{
                    mt: 2,
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none'
                    }
                }}
            />

            {editing && (
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    <LoadingButton
                        variant="outlined"
                        sx={{mt: 2}}
                        onClick={handleRowAdd}
                        disabled={saving || loading}
                        loading={saving}
                        startIcon={<AddIcon />}
                    >
                        Add New Row
                    </LoadingButton>
                </Box>
            )}
        </Box>
    );
};

const Sections = props => {
    const {saving, loading, editing} = props;
    const {control} = useFormContext();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {watch} = useFormContext();

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'sections'
    });

    const handleSectionAdd = useCallback(async() => {
        append({
            name: '',
            checks: []
        });
    }, [append]);

    const handleSectionRemove = useCallback(index => {
        enqueueSnackbar('Are you sure you want to remove this section?', {
            variant: 'warning',
            action: key => {
                return (
                    <>
                        <Button onClick={() => {
                            closeSnackbar(key);
                            remove(index);
                        }}>
                            Remove
                        </Button>
                        <Button onClick={() => closeSnackbar(key)}>
                            Cancel
                        </Button>
                    </>
                );
            }
        });
    }, [closeSnackbar, enqueueSnackbar, remove]);

    return (
        <Box>
            {fields.map((field, index) => {
                const {id} = field;
                const sectionName = watch(`sections.${index}.name`);

                return (
                    <Paper key={`secton-${id}`} sx={{flex: 1, mb: 2, p: 2}} elevation={1}>
                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                            {editing ? (
                                <>
                                    <TextField
                                        sx={{flex: 1}}
                                        disabled={!editing}
                                        label="Section Name"
                                        size="small"
                                        name={`sections.${index}.name`}
                                    />
                                    
                                    <LoadingButton
                                        color="primary"
                                        sx={{ml: 1}}
                                        width={190}
                                        variant="outlined"
                                        onClick={() => handleSectionRemove(index)}
                                        startIcon={<DeleteIcon />}
                                    >
                                        Remove Section
                                    </LoadingButton>
                                </>
                            ) : (
                                <Typography variant="h6" sx={{flex: 1}}>{sectionName}</Typography>
                            )}
                        </Box>

                        <SectionChecks {...props} sectionIndex={index} />
                    </Paper>
                );
            })}

            {editing && (
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    <LoadingButton
                        variant="contained"
                        onClick={handleSectionAdd}
                        disabled={saving || loading}
                        loading={saving}
                        startIcon={<AddIcon />}
                    >
                        Add New Section
                    </LoadingButton>
                </Box>
            )}
        </Box>
    );
};

const EditChecks = () => {
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [editing, setEditing] = useState(false);
    const [records, setRecords] = useState([]);
    const [activeRecord, setActiveRecord] = useState(null);
    const {enqueueSnackbar} = useSnackbar();
    const {currentUser} = useContext(UserContext);
    const {id: uid} = useParams();

    const methods = useForm({
        defaultValues: {
            checks: []
        },
        mode: 'onChange'
    });
    const {reset, handleSubmit} = methods;

    let isSubscribed = true;

    const fetch = useCallback(async() => {
        try {
            const ref = collection(db, 'checks');
            const q = query(ref, where('apparatus', '==', uid), where('type', '==', 'full'), orderBy('createdAt', 'desc'), limit(10));
            const raw = await getDocs(q);
            let docs = [];

            raw.forEach(doc => {
                const data = doc.data();

                docs.push({
                    id: doc.id,
                    uid: doc.id,
                    ...data
                });
            });

            docs = await populateUsers(db, docs);

            if (isSubscribed) {
                const [latestDoc] = docs.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());

                setRecords(docs);

                setActiveRecord(latestDoc);
                reset(latestDoc);
            }
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
            console.warn(e);
        }

        if (isSubscribed) {
            setLoading(false);
        }
    }, [enqueueSnackbar, db, uid, isSubscribed, reset]);

    useEffect(() => {
        fetch();
        
        return () => isSubscribed = false;
    }, [enqueueSnackbar, db, uid]);

    const handleSave = useCallback(async values => {
        const {sections} = values;

        try {
            setSaving(true);

            const docRef = collection(db, 'checks');
            await addDoc(docRef, {
                apparatus: uid,
                type: 'full',
                sections,
                createdAt: new Date(),
                user: currentUser.uid
            });

            setSaving(false);
            setEditing(false);

            await fetch();
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
            console.warn(e);

            setSaving(false);
        }
    }, [db, currentUser, enqueueSnackbar, uid, fetch]);

    const handleCancel = useCallback(async() => {
        setEditing(false);

        reset({
            ...activeRecord
        });
    }, [activeRecord, reset]);

    const handleActiveRecordChange = useCallback(record => {
        setActiveRecord(record);
        reset(record);
    }, [reset]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Card variant="outlined">
                        <CardContent sx={{pb: 0}}>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <Typography sx={{flex: 1}} variant="h6">History</Typography>
                            </Box>
                            {records.length ? (
                                <Timeline position="left" sx={{pb: 0}}>
                                    {records.map((record, index) => {
                                        const {user, createdAt} = record;
                                        const {fullName} = user || {};
                                        const isLast = index < (records.length - 1);
                                        const isActive = record === activeRecord;
                                        
                                        return (
                                            <TimelineItem sx={{opacity: isActive ? 1 : 0.6}} key={index} onClick={() => !editing && handleActiveRecordChange(record)}>
                                                <TimelineOppositeContent sx={{pt: 0}}>
                                                    <Typography variant="body1">
                                                        {moment(createdAt.toDate()).fromNow()}
                                                    </Typography>
                                                    <Box sx={{display: 'flex'}}>
                                                        <UserAvatar sx={{width: 20, height: 20, mr: 1}} user={user} />
                                                        <Typography variant="body2" color="text.secondary">
                                                            {fullName}
                                                        </Typography>
                                                    </Box>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot sx={{width: 18, height: 18}} color={isActive ? 'primary' : 'grey'} variant={isActive ? 'filled' : 'outlined'} />
                                                    {isLast && <TimelineConnector />}
                                                </TimelineSeparator>
                                            </TimelineItem>
                                        );
                                    })}
                                </Timeline>
                            ) : (
                                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', mt: 2}}>
                                    {loading ? (
                                        <CircularProgress color="primary" />
                                    ) : (
                                        <Typography variant="body2">No history</Typography>
                                    )}
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                <FormProvider {...methods}>
                    <Grid item xs={12} md={8}>
                        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mb: 2}}>
                            {editing ? (
                                <>
                                    <Button startIcon={<DoDisturbIcon />} size="small" sx={{mr: 1}} onClick={handleCancel}>Cancel</Button>
                                    <LoadingButton loading={saving} size="small" startIcon={<SaveIcon />} variant="contained" onClick={handleSubmit(handleSave)}>Save Version</LoadingButton>
                                </>
                            ) : (
                                <Button startIcon={<HistoryIcon />} size="small" variant="contained" disabled={loading} onClick={() => setEditing(true)}>Add Version</Button>
                            )}
                        </Box>

                        <Sections editing={editing} />
                    </Grid>
                </FormProvider>
            </Grid>
        </>
    );
};

export default EditChecks;