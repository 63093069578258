import React, {useState, useEffect} from 'react';
import {Button, Box, Container, Avatar, Typography, Alert, CircularProgress} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {verifyPasswordResetCode, confirmPasswordReset, applyActionCode} from 'firebase/auth';
import {useForm, FormProvider} from 'react-hook-form';
import queryString from 'query-string';

import {auth} from '-/firebase';

import TextField from '-/form/TextField.js';

const ResetPassword = props => {
    const {oobCode, continueUrl} = props;

    const [validating, setValidating] = useState(true);
    const [validCode, setValidCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [reset, setReset] = useState(false);

    const methods = useForm({
        defaultValues: {
            email: '',
            password: ''
        },
        mode: 'onChange'
    });
    const {handleSubmit, formState} = methods;
    const {isValid} = formState;

    const onSubmit = async data => {
        setLoading(true);

        const {password} = data;

        try {
            await confirmPasswordReset(auth, oobCode, password);
            setReset(true);

            if (continueUrl) {
                setTimeout(() => {
                    window.location = continueUrl;
                }, 1000);
            }
        } catch (e) {
            setLoading(false);

            setError(e.message);
        }
    };

    useEffect(() => {
        const go = async() => {
            try {
                await verifyPasswordResetCode(auth, oobCode);
                setValidCode(true);
                setValidating(false);
            } catch(e) {
                setValidCode(false);
                setValidating(false);
            }
        };

        go();
    }, [auth, oobCode]);

    return (
        <FormProvider {...methods}>
            <Container component="main" maxWidth="s" sx={{width: 400}}>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch'
                    }}
                >
                    <Box sx={{mt: 1, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        <img src="/logo.png" alt="JRFD" style={{width: '200px'}} />
                    </Box>

                    {validating === true && <CircularProgress />}

                    {reset === true && <Alert sx={{mt: 4}} severity="success">Password reset successfully!</Alert>}

                    {validating === false && validCode === false && <Alert sx={{mt: 4}} severity="error">Something went wrong. Please try again!</Alert>}

                    {validating === false && validCode === true && reset !== true && (
                        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="New Password"
                                name="password"
                                type="password"
                                disabled={loading}
                                rules={{required: true}}
                                {...{
                                    ...(error && {error: true, helperText: error})
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Confirm New Password"
                                name="confirmPassword"
                                type="password"
                                disabled={loading}
                                rules={{required: true}}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 1}}
                                onClick={handleSubmit(onSubmit)}
                                disabled={loading || !isValid}
                            >
                                Reset Password
                            </Button>
                        </Box>
                    )}
                </Box>
            </Container>
        </FormProvider>
    );
};

const VerifyEmail = props => {
    const {oobCode} = props;

    const [validating, setValidating] = useState(true);
    const [validCode, setValidCode] = useState(null);

    useEffect(() => {
        const go = async() => {
            try {
                await applyActionCode(auth, oobCode);
                setValidCode(true);
                setValidating(false);
            } catch(e) {
                setValidCode(false);
                setValidating(false);
            }
        };

        go();
    }, []);

    return (
        <Container component="main" maxWidth="s" sx={{width: 400}}>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch'
                }}
            >
                <Box sx={{mt: 1, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        TourneyTime
                    </Typography>
                </Box>

                {validating === true && <CircularProgress />}

                {validating === false && validCode === true && <Alert sx={{mt: 4}} severity="success">Email verified successfully!</Alert>}

                {validating === false && validCode === false && <Alert sx={{mt: 4}} severity="error">Something went wrong. Please try again!</Alert>}
            </Box>
        </Container>
    );
};

export default function Manage() {
    const parsed = queryString.parse(window.location.search) || {};
    const {mode} = parsed;

    if (mode === 'resetPassword') {
        return <ResetPassword {...parsed} />;
    } else if (mode === 'verifyEmail') {
        return <VerifyEmail {...parsed} />;
    }

    window.location = 'https://members.jrfd.ca';
};