import React, {useState, useContext} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import {Menu, MenuItem as MuiMenuItem, ListItemText} from '@mui/material';
import {paperClasses} from '@mui/material/Paper';
import {listClasses} from '@mui/material/List';
import Divider, {dividerClasses} from '@mui/material/Divider';
import ListItemIcon, {listItemIconClasses} from '@mui/material/ListItemIcon';
import {signOut} from 'firebase/auth';

import {auth} from '-/firebase';
import {UserContext} from '-/contexts/User';

import MenuButton from './MenuButton';

export default function() {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const {currentUser} = useContext(UserContext);
    const {isStation} = currentUser || {};

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const pushTo = path => {
        handleClose();
        
        if (pathname !== path) {
            navigate(path);
        }
    };

    const onLogOut = async() => {
        await signOut(auth);
        localStorage.removeItem('impersonating');

        navigate('/login');
    };

    return (
        <React.Fragment>
            <MenuButton
                aria-label="Open menu"
                onClick={handleClick}
                sx={{borderColor: 'transparent'}}
            >
                <MoreVertRoundedIcon />
            </MenuButton>
            <Menu
                anchorEl={anchorEl}
                id="menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                sx={{
                    [`& .${listClasses.root}`]: {
                        padding: '4px',
                        width: 130
                    },
                    [`& .${paperClasses.root}`]: {
                        padding: 0
                    },
                    [`& .${dividerClasses.root}`]: {
                        margin: '4px -4px'
                    }
                }}
            >
                {isStation ? [] : [
                    <MuiMenuItem key="menu-profile-item" onClick={() => pushTo('/profile')}>Profile</MuiMenuItem>,
                    <Divider key="menu-profile-divider" />
                ]}
                <MuiMenuItem
                    onClick={onLogOut}
                    sx={{
                        [`& .${listItemIconClasses.root}`]: {
                            ml: 'auto',
                            minWidth: 0
                        }
                    }}
                >
                    <ListItemText>Logout</ListItemText>
                    <ListItemIcon>
                        <LogoutRoundedIcon fontSize="small" />
                    </ListItemIcon>
                </MuiMenuItem>
            </Menu>
        </React.Fragment>
    );
}