import React, {useCallback, useContext} from 'react';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {Box} from '@mui/material';
import {get} from 'lodash';
import {GridActionsCellItem} from '@mui/x-data-grid-pro';
import {IncidentTypes} from '@embertracking/common';

import {Edit as EditIcon} from '@mui/icons-material';

import {hasPermission} from '-/data/utils';

import {UserContext} from '-/contexts/User';

import SearchableDataGrid from '-/components/SearchableDataGrid';

import Detail from './Detail';

const Grid = ({columns = [], ...rest}) => {
    const navigate = useNavigate();
    const {currentUser} = useContext(UserContext);
    const dateFormatLong = get(currentUser, 'settings.dateFormatLong') || 'LLL';

    const defaultColumns = [
        {
            field: 'date',
            headerName: 'Date',
            sortable: true,
            minWidth: 220,
            valueFormatter: value => {
                return value ? moment(value).format(dateFormatLong) : '-';
            }
        },
        // {field: 'uniqueId', headerName: '#', width: 160},
        {field: 'incidentNumber', headerName: '#', width: 160},
        {
            field: 'type',
            headerName: 'Type',
            minWidth: 150,
            width: 200,
            valueGetter: value => {
                return value ? IncidentTypes[value] : '-';
            }
        },
        {
            field: 'incidentTypeCode',
            headerName: 'Type',
            // minWidth: 150,
            valueGetter: (value, row) => {
                const {incidentTypeCode, incidentTypeDescription} = row || {};
                return [incidentTypeCode, incidentTypeDescription].filter(Boolean).join(' - ');
            }
        },
        {
            field: 'dispatchType',
            headerName: 'Dispatch Type',
            width: 300,
            valueGetter: (value, row) => {
                const {dispatch} = row || {};
                const {type} = dispatch || {};
                return type;
            }
        },
        {
            field: 'users',
            headerName: 'Members',
            width: 90,
            valueGetter: value => {
                return value ? value.length : 0;
            }
        },
        {
            field: 'durations.actual',
            headerName: 'Actual Duration',
            width: 150,
            valueGetter: (rawValue, row) => {
                const {durations} = row || {};
                const {total} = durations || {};

                if (!total) {
                    return;
                }

                return parseFloat(total);
            },
            valueFormatter: value => {
                if (!value || isNaN(value)) {
                    return;
                }

                const hours = Math.floor(value);
                const minutes = Math.round((value - hours) * 60);

                return new Intl.DurationFormat('en', {style: 'narrow'}).format({hours, minutes});
            },
            renderCell: ({value, formattedValue}) => {
                return (
                    <Box sx={{display: 'flex', p: 1, flexDirection: 'column'}}>
                        {formattedValue || value}
                    </Box>
                );
            }
        },
        {
            field: 'durations.salary',
            headerName: 'Duration',
            width: 100,
            valueGetter: (value, row) => {
                const {durations} = row || {};
                const {salary} = durations || {};

                if (!salary) {
                    return;
                }

                return parseFloat(salary);
            },
            valueFormatter: value => {
                if (!value || isNaN(value)) {
                    return;
                }

                const hours = Math.floor(value);
                const minutes = Math.round((value - hours) * 60);

                return new Intl.DurationFormat('en', {style: 'narrow'}).format({hours, minutes});
            },
            renderCell: ({value, formattedValue}) => {
                return formattedValue || value;
            }
        },
        {
            field: 'street',
            headerName: 'Location',
            minWidth: 250
        }
    ];

    const handleEdit = useCallback((params, e) => {
        if (!hasPermission(currentUser, 'incidents.write')) {
            return;
        }

        const {row} = params;
        const {metaKey} = e;

        const url = `/incidents/${row.id}`;
        if (metaKey) {
            window.open(url);
            return;
        }

        navigate(url);
    }, [navigate]);

    if (hasPermission(currentUser, 'incidents.write')) {
        defaultColumns.push({
            headerName: '',
            type: 'actions',
            field: 'actions',
            getActions: params => {
                return [
                    <GridActionsCellItem
                        key={`incidents-grid-edit-${params.row.id}`}
                        label="Edit"
                        icon={<EditIcon />}
                        onClick={e => handleEdit(params, e)}
                    />
                ];
            }
        });
    }

    const getDetailPanelContent = useCallback(({row}) => <Detail row={row} />, []);

    return (
        <SearchableDataGrid
            initialState={{
                sorting: {
                    sortModel: [
                        {field: 'date', sort: 'desc'}
                    ]
                },
                columns: {
                    columnVisibilityModel: {
                        incidentTypeCode: false,
                        dispatchType: false,
                        'durations.actual': false
                    }
                }
            }}
            slotProps={{
                loadingOverlay: {
                    variant: 'linear-progress',
                    noRowsVariant: 'skeleton'
                }
            }}
            onRowDoubleClick={handleEdit}
            columns={[...defaultColumns, ...columns].map(column => ({...column, disableColumnMenu: true}))}
            pageSizeOptions={[]}
            disableColumnFilter
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={() => 'auto'}
            {...rest}
        />
    );
};

export default Grid;